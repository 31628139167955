import { PostAPI } from 'Components/Helper/Helpers';

export const Insert = async request => {
    return PostAPI(request, 'Transaction/Insert');
};

export const SelectLastTenTx = async request => {
    return PostAPI(request, 'Transaction/SelectLastTen');
};

export const SelectForFilter = async request => {
    return PostAPI(request, 'Transaction/SelectForFilter');
};

export const SelectTransaction = async request => {
    return PostAPI(request, 'Transaction/SelectTransactionByID');
};

export const SelectSummaryForFilter = async request => {
    return PostAPI(request, 'Transaction/SelectSummaryForFilter');
};
export const PerDaySale = async request => {
    return PostAPI(request, 'Transaction/PerDaySale');
};
