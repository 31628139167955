import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Box from '@material-ui/core/Box';
import {
    LOCATION_UPDATE,
    LOCATION_SELECT_LOCATION,
    LOGOUT
} from 'ConstComponents';

import { MaterialTextField } from 'Components/Inputes/FormikMaterialInput';
import { CustomeAlert } from 'Components/Alert/Alert';
import { Container, Grid, Paper, Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from 'Components/Loader/Loader';
import { useHistory } from 'react-router';
import { commonStyle } from 'Components/CustomMaterialStyleSheets/CommonStyleSheet';
import { MESSAGE_CLOSE_AFTER } from 'Components/Helper/Messages';

export const LocationEdit = () => {
    const classes = commonStyle();
    const history = useHistory();
    const [responseMessage, setResponseMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [loadingOpen, setLoadingOpen] = useState(false);
    const [LocationObj, setLocationObj] = useState({
        LocationID: 0,
        Location: ''
    });

    const selectLocation = async () => {
        let URL = window.location.pathname;
        var res = URL.split('/');

        let LocationID = res[res.length - 1];

        let JSONObj = {
            LocationID: LocationID
        };

        var request = JSON.stringify(JSONObj);
        setIsError(false);
        setLoadingOpen(true);
        const response = await LOCATION_SELECT_LOCATION(request);

        setLoadingOpen(false);
        setSeverity('success');
        if (!response.isAuthorized) {
            LOGOUT();
        }
        var data = JSON.parse(response.data);
        if (!response.status) {
            setSeverity('error');
            return;
        }
        LocationObj.Location = data.Location;
        setLocationObj({
            LocationID: data.LocationID,
            Location: data.Location
        });
    };

    useEffect(() => {
        selectLocation();
    }, []);

    const submithandle = async (values, { setSubmitting, resetForm }) => {
        if (loading) {
            setLoading(false);
            return;
        }
        var requestObj = JSON.stringify(values);
        setIsError(false);
        setLoading(true);

        var response = await LOCATION_UPDATE(requestObj);

        if (!response.isAuthorized) LOGOUT();

        setIsError(true);
        setSeverity('success');
        setResponseMessage(response.data);

        if (!response.status) {
            setLoading(false);
            setSeverity('error');
            return;
        }

        resetForm(LocationObj);

        setTimeout(() => {
            history.push('/Location/Index');
        }, MESSAGE_CLOSE_AFTER);
    };

    const RegistrationScheama = Yup.object({
        Location: Yup.string().required('Please enter valid location')
    });

    return (
        <>
            {isError ? (
                <CustomeAlert
                    isOpen={isError}
                    message={responseMessage}
                    severity={severity}
                    onCloseCallback={alertState => {
                        setIsError(alertState);
                    }}
                />
            ) : null}
            <Backdrop className={classes.backdrop} open={loadingOpen}>
                <Loader />
            </Backdrop>
            <Formik
                enableReinitialize={true}
                initialValues={LocationObj}
                validationSchema={RegistrationScheama}
                onSubmit={submithandle}
                resetForm={LocationObj}
                render={formProps => {
                    return (
                        <Form>
                            <h2 className={classes.PaperHead_h2}>
                                {' '}
                                Location Details{' '}
                            </h2>
                            <Grid item xs={12}>
                                <Paper className={classes.PaperStyle}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={4} item>
                                            <MaterialTextField
                                                label="Location"
                                                name="Location"
                                                type="text"
                                                title="Location"
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>

                            <Grid className={classes.footerGrid}>
                                <Box className={classes.flexReverse}>
                                    <div className={classes.SubmitWrapper}>
                                        <Button
                                            type="submit"
                                            title="Submit"
                                            className={
                                                classes.CustomPrimaryButtonStyle
                                            }
                                            disabled={loading}
                                        >
                                            Update
                                        </Button>
                                        {loading && (
                                            <CircularProgress
                                                size={24}
                                                className={
                                                    classes.buttonProgress
                                                }
                                            />
                                        )}
                                    </div>

                                    <Button
                                        type="reset"
                                        title="Reset"
                                        className={
                                            classes.CustomDangerButtonStyle
                                        }
                                    >
                                        Reset
                                    </Button>
                                </Box>
                            </Grid>
                        </Form>
                    );
                }}
            />
        </>
    );
};
