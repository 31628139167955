import { PostAPI } from 'Components/Helper/Helpers';

export const Select = async request => {
    return PostAPI(request, 'Location/Select');
};
export const Add = async request => {
    return PostAPI(request, 'Location/Insert');
};
export const Delete = async request => {
    return PostAPI(request, 'Location/Delete');
};
export const SelectLocation = async request => {
    return PostAPI(request, 'Location/SelectLocationByID');
};
export const Update = async request => {
    return PostAPI(request, 'Location/Update');
};
