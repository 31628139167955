import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Box from '@material-ui/core/Box';
import { AGENT_SELECT_AGENT, AGENT_UPDATE, LOGOUT } from 'ConstComponents';
import { MaterialTextField } from 'Components/Inputes/FormikMaterialInput';
import { MaterialNumberField } from 'Components/Inputes/FormikMaterialNumberInput';
import { CustomeAlert } from 'Components/Alert/Alert';
import { Grid, Paper, Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from 'Components/Loader/Loader';
import { useHistory } from 'react-router';
import { commonStyle } from 'Components/CustomMaterialStyleSheets/CommonStyleSheet';
import { MaterialDatePickerField } from 'Components/Inputes/MaterialDatePickerField';

import { MESSAGE_CLOSE_AFTER } from 'Components/Helper/Messages';
export const AgentEdit = () => {
    const classes = commonStyle();
    const history = useHistory();
    const [responseMessage, setResponseMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [loadingOpen, setLoadingOpen] = useState(false);

    const [AgentObj, setAgentObj] = useState({
        AgentID: 0,
        AgentName: '',
        AgentMobile: '',
        AgentPanNumber: '',
        AgentDOJ: '',
        AgentAddress: '',
        AgentPanPhoto: '',
        AgentAdharcardPhoto: ''
    });

    const selectAgent = async () => {
        let URL = window.location.pathname;
        var res = URL.split('/');

        let AgentID = res[res.length - 1];

        let JSONObj = {
            AgentID: AgentID
        };

        var request = JSON.stringify(JSONObj);
        setIsError(false);
        setLoadingOpen(true);

        const response = await AGENT_SELECT_AGENT(request);

        setLoadingOpen(false);
        setSeverity('success');

        if (!response.isAuthorized) {
            LOGOUT();
        }
        var data = JSON.parse(response.data);

        if (!response.status) {
            setSeverity('error');
            return;
        }

        AgentObj.AgentName = data.AgentName;
        setAgentObj({
            AgentID: data.AgentID,
            AgentName: data.AgentName,
            AgentMobile: data.AgentMobile,
            AgentPanNumber: data.AgentPanNumber,
            AgentPanPhoto: data.AgentPanPhoto,
            AgentAdharcardPhoto: data.AgentAdharcardPhoto,
            AgentAddress: data.AgentAddress,
            AgentDOJ: data.AgentDOJ
        });
    };

    useEffect(() => {
        selectAgent();
    }, []);

    const submithandle = async (values, { setSubmitting, resetForm }) => {
        if (loading) {
            setLoading(false);
            return;
        }
        var requestObj = JSON.stringify(values);
        setIsError(false);

        setLoading(true);
        setSubmitting(true);

        var response = await AGENT_UPDATE(requestObj);
        if (!response.isAuthorized) {
            LOGOUT();
        }

        setIsError(true);

        setSeverity('success');
        setResponseMessage(response.data);

        if (!response.status) {
            setSeverity('error');
            setLoading(false);
            return;
        }

        resetForm(AgentObj);

        setTimeout(() => {
            history.push('/Agent/Index');
        }, MESSAGE_CLOSE_AFTER);

        // CustomRedirtection();
    };

    const RegistrationScheama = Yup.object({
        AgentName: Yup.string().required('Please enter valid agent name.'),

        AgentMobile: Yup.string()
            .length(10, 'Please enter valid mobile number')
            .required('Required'),

        AgentPanNumber: Yup.string()
            .length(15, 'Please enter valid pan number')
            .required('Required'),

        AgentDOJ: Yup.date()
            .nullable('Please select valid date')
            .max(new Date(), 'Please select valid date')
            .required('Please select valid date'),

        AgentAddress: Yup.string().required('Please enter valid address')
    });

    return (
        <>
            {isError ? (
                <CustomeAlert
                    isOpen={isError}
                    message={responseMessage}
                    severity={severity}
                    onCloseCallback={alertState => {
                        setIsError(alertState);
                    }}
                />
            ) : null}
            <Backdrop className={classes.backdrop} open={loadingOpen}>
                <Loader />
            </Backdrop>
            <Formik
                enableReinitialize={true}
                initialValues={AgentObj}
                validationSchema={RegistrationScheama}
                onSubmit={submithandle}
                resetForm={AgentObj}
                render={formProps => {
                    return (
                        <Form>
                            <h2 className={classes.PaperHead_h2}>
                                Personal Details
                            </h2>

                            <Grid item xs={12}>
                                <Paper className={classes.PaperStyle}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <MaterialTextField
                                                label="Agent Name"
                                                name="AgentName"
                                                type="text"
                                                title="Agent Name"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <MaterialNumberField
                                                label="Mobile Number"
                                                name="AgentMobile"
                                                type="text"
                                                length={10}
                                                title="Mobile Number"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <MaterialTextField
                                                label="Address"
                                                name="AgentAddress"
                                                type="text"
                                                title="Address"
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>

                            <Grid item xs={12}>
                                <Paper className={classes.PaperStyle}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <MaterialDatePickerField
                                                name="AgentDOJ"
                                                label="Agent DOJ"
                                                maxDate={new Date()}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <MaterialTextField
                                                label="Agent Pan Number"
                                                name="AgentPanNumber"
                                                length={15}
                                                type="text"
                                                title="Agent Pan Number"
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>

                            <Grid item xs={12}>
                                <Paper className={classes.PaperStyle}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <MaterialTextField
                                                label="Pan Photo"
                                                name="AgentPanPhoto"
                                                type="text"
                                                title="Agent Pan Photo"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <MaterialTextField
                                                label="Adhar card Photo"
                                                name="AgentPanPhoto"
                                                type="text"
                                                title="Agent Pan Photo"
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid className={classes.footerGrid}>
                                <Box className={classes.flexReverse}>
                                    <div className={classes.SubmitWrapper}>
                                        <Button
                                            type="submit"
                                            title="Submit"
                                            className={
                                                classes.CustomPrimaryButtonStyle
                                            }
                                            disabled={loading}
                                        >
                                            Update
                                        </Button>
                                        {loading && (
                                            <CircularProgress
                                                size={24}
                                                className={
                                                    classes.buttonProgress
                                                }
                                            />
                                        )}
                                    </div>

                                    <Button
                                        type="reset"
                                        title="Reset"
                                        className={
                                            classes.CustomDangerButtonStyle
                                        }
                                    >
                                        Reset
                                    </Button>
                                </Box>
                            </Grid>
                        </Form>
                    );
                }}
            />
        </>
    );
};
