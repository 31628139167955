import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';

import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2)
        }
    }
}));

export function CustomeAlert({
    isOpen,
    severity,
    message,
    onCloseCallback,
    closeTime
}) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(isOpen);
    const timeoutDelay = closeTime ? closeTime : 10;

    useEffect(() => {
        const timer = setTimeout(() => {
            setOpen(false);
        }, timeoutDelay * 1000);
        return () => clearTimeout(timer);
    }, []);

    const handleModalClose = () => {
        setOpen(false);
        onCloseCallback(false);
    };
    return (
        <div className={classes.root}>
            <Collapse in={open}>
                <Alert
                    severity={severity}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                handleModalClose();
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {message}
                </Alert>
            </Collapse>
        </div>
    );
}
