import { PostAPI } from 'Components/Helper/Helpers';

export const Select = async request => {
    return PostAPI(request, 'PaymentMode/Select');
};
export const Add = async request => {
    return PostAPI(request, 'PaymentMode/Insert');
};
export const Delete = async request => {
    return PostAPI(request, 'PaymentMode/Delete');
};
export const SelectPaymentMode = async request => {
    return PostAPI(request, 'PaymentMode/SelectPaymentModeByID');
};
export const Activate = async request => {
    return PostAPI(request, 'PaymentMode/Activate');
};

export const Update = async request => {
    return PostAPI(request, 'PaymentMode/Update');
};
