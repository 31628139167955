import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Box from '@material-ui/core/Box';
import { RECONCILATION_UPLOAD, LOGOUT } from 'ConstComponents';

import { MaterialTextField } from 'Components/Inputes/FormikMaterialInput';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CustomeAlert } from 'Components/Alert/Alert';

import { Grid, Paper, Button } from '@material-ui/core';
import { commonStyle } from 'Components/CustomMaterialStyleSheets/CommonStyleSheet';
import { MaterialDatePickerField } from 'Components/Inputes/MaterialDatePickerField';
export const ReconciliationUpload = () => {
    const classes = commonStyle();

    const [responseMessage, setResponseMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [loading, setLoading] = useState(false);

    const [Document, setDocument] = useState('');
    var FormObj = {
        Date: '',
        ExcelDocument: {
            Name: '',
            Size: '',
            Type: '',
            Data: ''
        }
    };

    const submithandle = async (values, { setSubmitting, resetForm }) => {
        var requestObj = JSON.stringify(values);
        setIsError(false);
        setLoading(true);
        var response = await RECONCILATION_UPLOAD(requestObj);

        if (!response.isAuthorized) LOGOUT();
        setLoading(false);
        setSeverity('success');
        setIsError(true);
        setResponseMessage(response.data);
        if (!response.status) {
            setLoading(false);
            setSeverity('error');
            return;
        }

        resetForm(FormObj);
    };

    const ReconcilationScheama = Yup.object({
        // ExcelDocument: Yup.object().shape({
        //     Type: Yup.string()
        //         .required(PLEASE_PROVIDE_VALID + 'excel')
        //         .matches(/(\.xlsx|\.xlsm|\.xls)$/i,"Please select only excel file")
        // })
    });
    const resethandle = resetForm => {};
    return (
        <>
            {isError ? (
                <CustomeAlert
                    isOpen={isError}
                    message={responseMessage}
                    severity={severity}
                    onCloseCallback={alertState => {
                        setIsError(alertState);
                    }}
                />
            ) : null}
            <Formik
                enableReinitialize={true}
                initialValues={FormObj}
                validationSchema={ReconcilationScheama}
                onSubmit={submithandle}
                onReset={resethandle}
                render={formProps => {
                    return (
                        <Form>
                            <h2 className={classes.PaperHead_h2}>
                                Reconcilation Upload
                            </h2>
                            <Grid item xs={12}>
                                <Paper className={classes.PaperStyle}>
                                    <Grid container spacing={2}>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={3}
                                            lg={3}
                                        >
                                            <MaterialTextField
                                                id="file"
                                                name="DocumentFile"
                                                type="file"
                                                onChange={e => {
                                                    var file =
                                                        e.target.files[0];

                                                    setDocument(
                                                        URL.createObjectURL(
                                                            file
                                                        )
                                                    );

                                                    var reader = new FileReader();
                                                    formProps.setFieldValue(
                                                        'ExcelDocument.Name',
                                                        file.name
                                                    );
                                                    formProps.setFieldValue(
                                                        'ExcelDocument.Size',
                                                        file.size
                                                    );
                                                    formProps.setFieldValue(
                                                        'ExcelDocument.Type',
                                                        file.type
                                                    );
                                                    reader.onload = function(
                                                        item
                                                    ) {
                                                        formProps.setFieldValue(
                                                            'ExcelDocument.Data',
                                                            item.target.result
                                                                .replace(
                                                                    'data:',
                                                                    ''
                                                                )
                                                                .replace(
                                                                    /^.+,/,
                                                                    ''
                                                                )
                                                        );
                                                    };

                                                    reader.readAsDataURL(file);
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={3}>
                                            <MaterialDatePickerField
                                                name="Date"
                                                maxDate={new Date()}
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>

                            <Grid className={classes.footerGrid}>
                                <Box className={classes.flexReverse}>
                                    <div className={classes.SubmitWrapper}>
                                        <Button
                                            type="submit"
                                            title="Save"
                                            className={
                                                classes.CustomPrimaryButtonStyle
                                            }
                                            disabled={loading}
                                        >
                                            Upload
                                        </Button>
                                        {loading && (
                                            <CircularProgress
                                                size={24}
                                                className={
                                                    classes.buttonProgress
                                                }
                                            />
                                        )}
                                    </div>
                                </Box>
                            </Grid>
                        </Form>
                    );
                }}
            />
        </>
    );
};
