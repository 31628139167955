import { PostAPI } from 'Components/Helper/Helpers';
export const Add = async request => {
    return PostAPI(request, 'Agent/Insert');
};

export const SelectAgentList = async request => {
    return PostAPI(request, 'Agent/Select');
};
export const SelectAgentByID = async request => {
    return PostAPI(request, 'Agent/SelectAgentByID');
};
export const Delete = async request => {
    return PostAPI(request, 'Agent/Delete');
};

export const SetAsUser = async request => {
    return PostAPI(request, 'Agent/SetAsUser');
};

export const Activate = async request => {
    return PostAPI(request, 'Agent/Activate');
};
export const Update = async request => {
    return PostAPI(request, 'Agent/Update');
};
