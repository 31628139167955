import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

export const commonStyle = makeStyles(theme => ({
    LinkButton: {
        background: '#0277bd',
        color: '#fff',
        cursor: 'pointer'
    },

    PaperHead_h2: {
        alignItems: 'center',
        display: 'flex',
        minHeight: '36px',
        fontWeight: '400',
        font: '500 20px Roboto,RobotoDraft,Helvetica,Arial,sans-serif',
        marginRight: '16px',
        marginLeft: '0px',
        marginTop: '10px',
        marginBottom: '5px'
    },

    PaperHead_Blank: {
        alignItems: 'center',
        display: 'flex',
        marginRight: '16px',
        marginLeft: '0px',
        marginTop: '8px',
        marginBottom: '4px'
    },

    CustomPrimaryButtonStyle: {
        background: '#0277bd',
        color: '#fff',
        cursor: 'pointer',
        border: 'none',
        textAlign: 'center',
        minHeight: '36px',
        display: 'inline-block',
        verticalAlign: 'middle',
        '&:hover': {
            background: '#0168a6'
        },
        '&:desabled': {
            background: '#000'
        }
    },

    CustomDangerButtonStyle: {
        background: '#CCD5E0',
        color: '#283174',
        cursor: 'pointer',
        border: 'none',
        textAlign: 'center',
        minHeight: '36px',
        display: 'inline-block',
        padding: '0 8px',
        verticalAlign: 'middle',
        marginRight: '1em',
        '&:hover': {
            background: '#CCD5E1'
        }
    },

    FooterStyle: {
        position: 'fixed',
        minWidth: '100%',
        height: '64px',
        bottom: 0,
        borderColor: '#f1f1f1',
        background: '#fff',
        left: 0,
        zIndex: '1500',
        borderStyle: 'solid',
        borderTopWidth: '1px',
        padding: '10px'
    },

    PaperStyle: {
        paddingBottom: '40px',
        display: 'flex',
        flexDirection: 'row'
    },

    VerticalFormContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center'
    },
    VerticalForm: {
        width: '55em'
    },

    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },

    PaperFooterContainer: {
        height: '100%',
        alignItems: 'center',
        verticalAlign: 'middle',
        justifyContent: 'center',
        marginLeft: '300px'
    },
    PaperFooter: {
        display: 'flex',
        marginTop: '12px',
        alignItems: 'center',
        maxWidth: '960px',
        flexDirection: 'row-reverse '
    },

    SubmitWrapper: {
        position: 'relative'
    },

    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    },

    numbericRightAlign: {
        textAlign: 'right',
        paddingRight: 24
    },
    footerGrid: {
        marginTop: '8px',
        marginBottom: '8px'
    },

    flexReverse: {
        display: 'flex',
        flexDirection: 'row-reverse'
    }
}));
