import React, { useRef, useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { USER_FORGOT_PASSWORD } from 'ConstComponents';

import { MaterialNumberField } from 'Components/Inputes/FormikMaterialNumberInput';
import { Button, Grid, FormControl, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { CustomeAlert } from 'Components/Alert/Alert';
import {
    PLEASE_PROVIDE_VALID,
    MESSAGE_CLOSE_AFTER
} from 'Components/Helper/Messages';

const useStyles = makeStyles(theme => ({
    login_form: {
        display: 'flex',
        flexDirection: 'column'
    },
    login_title: {
        fontFamily: 'Raleway-Medium',
        fontSize: '20px',
        lineHeight: '1.2',
        textAlign: 'center',
        textTransform: 'uppercase'
    },
    modalContent: {
        position: 'absolute',
        background: '#fff',
        padding: theme.spacing(2)
    }
}));

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}
export const ForgotPassword = ({ modalOpen, onDismissCallback }) => {
    const classes = useStyles();

    const [responseMessage, setResponseMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(modalOpen);
    const [severity, setSeverity] = useState('success');

    const handleModalClose = () => {
        setOpen(false);
        onDismissCallback(false);
    };

    const ModelObj = {
        MobileNumber: ''
    };

    const validationSchema = Yup.object({
        MobileNumber: Yup.string()
            .length(10, PLEASE_PROVIDE_VALID + 'mobile number')
            .required(PLEASE_PROVIDE_VALID + 'mobile number')
    });

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        var request = JSON.stringify(values);
        setIsError(false);
        const response = await USER_FORGOT_PASSWORD(request);
        setSeverity('success');
        setResponseMessage(response.data);
        setIsError(true);

        if (!response.status) {
            setSeverity('error');
            return;
        }

        resetForm(ModelObj);
        setSubmitting(false);

        setTimeout(() => {
            handleModalClose();
        }, MESSAGE_CLOSE_AFTER);
    };

    return (
        <>
            <Modal open={open} onClose={handleModalClose}>
                <div style={modalStyle} className={classes.modalContent}>
                    {isError ? (
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            style={{ marginTop: '2em' }}
                        >
                            <CustomeAlert
                                message={responseMessage}
                                isOpen={isError}
                                severity={severity}
                                onCloseCallback={AlertState => {
                                    setIsError(AlertState);
                                }}
                            />
                        </Grid>
                    ) : null}
                    <Formik
                        initialValues={ModelObj}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        resetForm={ModelObj}
                    >
                        <Form className={classes.login_form}>
                            <FormControl className={classes.login_title}>
                                Forgot Password
                            </FormControl>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <MaterialNumberField
                                        type="text"
                                        name="MobileNumber"
                                        length={10}
                                        label="MobileNumber"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <FormControl fullWidth>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                        >
                                            Submit
                                        </Button>

                                        <Button
                                            style={{ marginTop: 10 }}
                                            variant="contained"
                                            color="secondary"
                                            onClick={handleModalClose}
                                        >
                                            Close
                                        </Button>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Form>
                    </Formik>
                </div>
            </Modal>
        </>
    );
};
