import React from 'react';
import { NavLink } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import InboxIcon from '@material-ui/icons/Inbox';

import { CustomIcon } from 'Components/CustomIcons';

export const SingleElement = ({ name, url, classes, icon }) => {
    return (
        <div key={name}>
            <NavLink to={url} style={{ textDecoration: 'none', color: '#000' }}>
                <ListItem button key={name} className={classes}>
                    <ListItemIcon>
                        {icon === null ? (
                            <InboxIcon />
                        ) : (
                            <CustomIcon icon={icon} />
                        )}
                    </ListItemIcon>
                    <ListItemText primary={name} />
                </ListItem>
            </NavLink>
        </div>
    );
};
