import React, { useState, useEffect } from 'react';
import { Container, Grid, Paper } from '@material-ui/core';
import { SELECT_DASHBOARD_DETAILS, LOGOUT } from 'ConstComponents';

import Today from 'Assets/Images/Today.jpg';
import Month from 'Assets/Images/Month.jpg';
import Customer from 'Assets/Images/Customer.jpg';
import { DashBoardCard } from 'Components/SummaryCards/DashBoardCard';

import AreaGraphChart from 'Components/SummaryCards/AreaGraphChart';
import { commonStyle } from 'Components/CustomMaterialStyleSheets/CommonStyleSheet';
export const FirmOwnerDashboard = () => {
    const classes = commonStyle();
    const [loading, setLoading] = useState(false);
    const [CustomerObj, setCustomerObj] = useState({
        TodaysSale: 0,
        WeekSale: 0,
        MonthSale: 0,
        TotalCustomer: 0
    });

    const selectDashboardDetails = async values => {
        setLoading(true);
        var request = JSON.stringify(values);
        setLoading(false);
        const response = await SELECT_DASHBOARD_DETAILS(request);

        if (!response.isAuthorized) {
            LOGOUT();
        }

        if (!response.status) {
            return;
        }

        var data = JSON.parse(response.data);

        setCustomerObj({
            TodaysSale: data.TodaySale,
            WeekSale: data.WeekSale,
            MonthSale: data.MonthSale,
            TotalCustomer: data.TotalCustomer
        });
    };
    useEffect(() => {
        selectDashboardDetails();
    }, []);

    return (
        <>
            <Grid item xs={12}>
                <Paper className={classes.PaperStyle}>
                    <Grid container spacing={3}>
                        <DashBoardCard
                            image={Today}
                            title={'Today Sale'}
                            value={CustomerObj.TodaysSale}
                        />

                        <DashBoardCard
                            image={Month}
                            title={'Month Sale'}
                            value={CustomerObj.MonthSale}
                        />

                        <DashBoardCard
                            image={Customer}
                            title={'Customer'}
                            value={CustomerObj.TotalCustomer}
                        />
                    </Grid>
                </Paper>
            </Grid>

            <Grid item xs={12}>
                <Paper className={classes.PaperStyle}>
                    <Grid container spacing={3}>
                        <AreaGraphChart />
                    </Grid>
                </Paper>
            </Grid>
        </>
    );
};
