import { PostAPI } from 'Components/Helper/Helpers';

export const LoginAPI = async request => {
    return PostAPI(request, 'Credential/Login');
};
export const ForgotPasswordAPI = async request => {
    return PostAPI(request, 'Credential/ForgotPassword');
};

export const Add = async request => {
    return PostAPI(request, 'User/Register');
};

export const SelectUserList = async request => {
    return PostAPI(request, 'User/Select');
};

export const SelectForDropDown = async request => {
    return PostAPI(request, 'User/SelectForDropDown');
};

export const Delete = async request => {
    return PostAPI(request, 'User/Delete');
};

export const SelectUser = async request => {
    return PostAPI(request, 'User/SelectMemberByID');
};

export const Activate = async request => {
    return PostAPI(request, 'User/Activate');
};
