var LOCAL_API_URL = '';
var LOCAL_CLIENT_NAME = '';
var LOCAL_LOGO_WIDTH = '';

const readJsonSync = async () => {
    const json = await readJson();

    LOCAL_API_URL = json.REACT_APP_API_URL;

    LOCAL_CLIENT_NAME = json.REACT_APP_CLIENT_NAME;
    LOCAL_LOGO_WIDTH = json.REACT_APP_LOGO_WIDTH;
};

const readJson = async () => {
    const response = await fetch(`${process.env.PUBLIC_URL}/config.json`);
    const json = await response.json();
    return json;
};

export const VARIANT = 'outlined';

export const API_URL = async () => {
    if (LOCAL_API_URL === undefined || LOCAL_API_URL === '') {
        await readJsonSync();
    }

    return LOCAL_API_URL;
};

export const CLIENT_NAME = async () => {
    if (LOCAL_CLIENT_NAME === undefined || LOCAL_CLIENT_NAME === '') {
        await readJsonSync();
    }

    return LOCAL_CLIENT_NAME;
};

export const LOGO_WIDTH = async () => {
    if (LOCAL_LOGO_WIDTH === undefined || LOCAL_LOGO_WIDTH === '') {
        await readJsonSync();
    }

    return LOCAL_LOGO_WIDTH;
};

export const PRIMARY_COLOR = () => {
    return localStorage.getItem('PrimaryColor') || '#7467ef';
};
export const SECONDARY_COLOR = () => {
    return localStorage.getItem('SecondaryColor') || '#7467ef';
};
export const PRIMARY_HOVER_COLOR = () => {
    return localStorage.getItem('PrimaryHoverColor') || '#7467ef';
};

export const SECONDARY_HOVER_COLOR = () => {
    return localStorage.getItem('SecondaryHoverColor') || '#7467ef';
};

export const APP_BAR_COLOR = () => {
    return localStorage.getItem('AppBarColor') || '#7467ef';
};

export const NAV_BAR_COLOR = () => {
    return localStorage.getItem('NavBarColor') || '#c4c4c4';
};

export const RESET_COLOR = () => {
    //return localStorage.getItem('ResetColor') || '#fff';
    return '#fff';
};

export const CONSUMER_NUMBER_FONT_SIZE = async () => {
    return localStorage.getItem('ConsumerNumberFontSize') || '24';
};
