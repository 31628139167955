import React, { useEffect, useState } from 'react';
import { useField } from 'formik';

import { KeyboardDatePicker } from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'react-datepicker/dist/react-datepicker.css';

// pick a date util library
import DateFnsUtils from '@date-io/date-fns';

export const MaterialDatePickerField = ({ label, ...props }) => {
    const [field, meta, helpers] = useField(props);

    const [selectedDate, setSelectedDate] = useState(new Date());

    useEffect(() => {
        setSelectedDate(meta.value ? meta.value : null);
    });

    const handleDateChange = date => {
        meta.value = date;
        helpers.setValue(date);
        setSelectedDate(date);
    };
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                {...props}
                {...field}
                label={label}
                clearable
                format="dd/MMM/yyyy"
                autoOk
                value={selectedDate || ''}
                onChange={handleDateChange}
            />
        </MuiPickersUtilsProvider>
    );
};
