import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Box from '@material-ui/core/Box';
import { MaterialDatePickerField } from 'Components/Inputes/MaterialDatePickerField';

import { Grid, Button, Paper } from '@material-ui/core';

import { CustomeAlert } from 'Components/Alert/Alert';
import { LOGOUT, RECONCILATION_SELECT } from 'ConstComponents';

import Backdrop from '@material-ui/core/Backdrop';
import ReactTable from 'Components/ReactTable/ReactTable';
import Loader from 'Components/Loader/Loader';

import CircularProgress from '@material-ui/core/CircularProgress';
import { commonStyle } from 'Components/CustomMaterialStyleSheets/CommonStyleSheet';
import { PLEASE_SELECT_VALID } from 'Components/Helper/Messages';

export const ReconcilationReport = () => {
    const classes = commonStyle();
    const [transactions, setTransactions] = useState([]);
    const [mainColumns, setMainColumns] = useState([]);
    const [loadingOpen, setLoadingOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [severity, setSeverity] = useState('success');

    const [ModelObj] = useState({
        FromDate: new Date()
    });

    const submithandle = async (values, { setSubmitting, resetForm }) => {
        setTransactions([]);
        var requestObj = JSON.stringify(values);

        setIsError(false);
        setLoading(true);
        setLoadingOpen(true);
        var response = await RECONCILATION_SELECT(requestObj);

        setLoading(false);
        setLoadingOpen(false);
        if (!response.isAuthorized) LOGOUT();

        setSeverity('success');

        if (!response.status) {
            setIsError(true);
            setResponseMessage(response.data);
            setSeverity('error');
            return;
        }

        setSubmitting(false);
        var data = JSON.parse(response.data);
        setTransactions(data);
    };

    const validationSchema = Yup.object({
        FromDate: Yup.date()
            .nullable(PLEASE_SELECT_VALID + 'from date')
            .required(PLEASE_SELECT_VALID + 'date')
            .max(new Date(), PLEASE_SELECT_VALID + 'from date')
            .typeError(PLEASE_SELECT_VALID + 'from date')
    });

    useEffect(() => {
        createColumns();
    }, []);

    const createColumns = () => {
        var columns = [];

        columns.push({
            label: '#',
            name: 'ID',
            srno: true
        });

        columns.push({
            label: 'SetBoxNumber',
            name: 'STBNo'
        });

        columns.push({
            label: 'Name',
            name: 'UserName'
        });

        columns.push({
            label: 'MobileNo',
            name: 'MobileNo'
        });
        columns.push({
            label: 'Total Trans SumAmt',
            name: 'TotalTransSumAmount',
            numeric: true
        });

        columns.push({
            label: 'Reconcile file SumAmt',
            name: 'TotalReconcileSumAmount',
            numeric: true
        });
        columns.push({
            label: 'Difference',
            name: 'Difference',
            numeric: true
        });

        setMainColumns(columns);
    };

    return (
        <>
            <Backdrop className={classes.backdrop} open={loadingOpen}>
                <Loader />
            </Backdrop>

            {isError ? (
                <CustomeAlert
                    isOpen={isError}
                    message={responseMessage}
                    severity={severity}
                    onCloseCallback={alertState => {
                        setIsError(alertState);
                    }}
                />
            ) : null}
            <Formik
                initialValues={ModelObj}
                validationSchema={validationSchema}
                onSubmit={submithandle}
                resetForm={ModelObj}
                render={formProps => {
                    return (
                        <Form>
                            <h2 className={classes.PaperHead_h2}>
                                Payment Report
                            </h2>
                            <Grid item xs={12}>
                                <Paper className={classes.PaperStyle}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <MaterialDatePickerField
                                                name="FromDate"
                                                label=" Date"
                                                maxDate={new Date()}
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>

                            <Grid className={classes.footerGrid}>
                                <Box className={classes.flexReverse}>
                                    <div className={classes.SubmitWrapper}>
                                        <Button
                                            type="submit"
                                            title="submit"
                                            className={
                                                classes.CustomPrimaryButtonStyle
                                            }
                                            disabled={loading}
                                        >
                                            Search
                                        </Button>
                                        {loading && (
                                            <CircularProgress
                                                size={24}
                                                className={
                                                    classes.buttonProgress
                                                }
                                            />
                                        )}
                                    </div>
                                </Box>
                            </Grid>
                        </Form>
                    );
                }}
            />

            <ReactTable List={transactions} columns={mainColumns} />
        </>
    );
};
