import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Box from '@material-ui/core/Box';
import { PACKAGE_ADD, LOGOUT } from 'ConstComponents';

import { MaterialTextField } from 'Components/Inputes/FormikMaterialInput';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CustomeAlert } from 'Components/Alert/Alert';
import { useHistory } from 'react-router';
import { Container, Grid, Paper, Button } from '@material-ui/core';
import { commonStyle } from 'Components/CustomMaterialStyleSheets/CommonStyleSheet';
import { MESSAGE_CLOSE_AFTER } from 'Components/Helper/Messages';

export const PackageAdd = () => {
    const classes = commonStyle();
    const history = useHistory();
    const [responseMessage, setResponseMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [loading, setLoading] = useState(false);

    var PackageObj = {
        PackageName: '',
        Price: ''
    };

    const submithandle = async (values, { setSubmitting, resetForm }) => {
        var requestObj = JSON.stringify(values);
        setIsError(false);
        setLoading(true);
        var response = await PACKAGE_ADD(requestObj);

        if (!response.isAuthorized) LOGOUT();

        setSeverity('success');
        setIsError(true);
        setResponseMessage(response.data);
        if (!response.status) {
            setLoading(false);
            setSeverity('error');
            return;
        }

        resetForm(PackageObj);

        setTimeout(() => {
            history.push('/Package/Index');
        }, MESSAGE_CLOSE_AFTER);
    };

    const RegistrationScheama = Yup.object({
        PackageName: Yup.string()
            .trim()
            .required('Please enter valid Package.'),
        Price: Yup.number()
            .moreThan(-1, 'Please enter valid price')
            .required('Please enter valid price')
    });
    const resethandle = resetForm => {};
    return (
        <>
            {isError ? (
                <CustomeAlert
                    isOpen={isError}
                    message={responseMessage}
                    severity={severity}
                    onCloseCallback={alertState => {
                        setIsError(alertState);
                    }}
                />
            ) : null}
            <Formik
                enableReinitialize={true}
                initialValues={PackageObj}
                validationSchema={RegistrationScheama}
                onSubmit={submithandle}
                onReset={resethandle}
                render={formProps => {
                    return (
                        <Form>
                            <h2 className={classes.PaperHead_h2}>
                                {' '}
                                Package Details{' '}
                            </h2>
                            <Grid item xs={12}>
                                <Paper className={classes.PaperStyle}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={4} item>
                                            <MaterialTextField
                                                label="PackageName"
                                                name="PackageName"
                                                type="text"
                                                title="PackageName"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} item>
                                            <MaterialTextField
                                                label="Price"
                                                name="Price"
                                                type="text"
                                                title="Price"
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>

                            <Grid className={classes.footerGrid}>
                                <Box className={classes.flexReverse}>
                                    <div className={classes.SubmitWrapper}>
                                        <Button
                                            type="submit"
                                            title="Register"
                                            className={
                                                classes.CustomPrimaryButtonStyle
                                            }
                                            disabled={loading}
                                        >
                                            Add
                                        </Button>
                                        {loading && (
                                            <CircularProgress
                                                size={24}
                                                className={
                                                    classes.buttonProgress
                                                }
                                            />
                                        )}
                                    </div>

                                    <Button
                                        type="reset"
                                        title="Reset"
                                        className={
                                            classes.CustomDangerButtonStyle
                                        }
                                    >
                                        Reset
                                    </Button>
                                </Box>
                            </Grid>
                        </Form>
                    );
                }}
            />
        </>
    );
};
