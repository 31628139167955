import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import menuItems from './menuItems';
import { SingleElement } from './SingleElement';
import { DropDownMenu } from './DropDownMenu';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 400,
        backgroundColor: theme.palette.background.paper
    },
    nested: {
        paddingLeft: theme.spacing(4)
    }
}));

export function CustomMenu() {
    const classes = useStyles();
    var currentUsertypeid = sessionStorage.getItem('UserTypeID');

    function handler(children) {
        return children.map((subOption, index) => {
            if (subOption.usertypeid === -99) {
                return !subOption.children ? (
                    <SingleElement
                        key={index}
                        name={subOption.name}
                        url={subOption.url}
                        icon={subOption.icon}
                        backgroundColor={green}
                    />
                ) : (
                    <DropDownMenu
                        key={index}
                        name={subOption.name}
                        children={subOption.children}
                        icon={subOption.icon}
                        backgroundColor={green}
                    />
                );
            }

            if (subOption.usertypeid === parseInt(currentUsertypeid)) {
                return !subOption.children ? (
                    <SingleElement
                        key={index}
                        name={subOption.name}
                        url={subOption.url}
                        icon={subOption.icon}
                        backgroundColor={green}
                    />
                ) : (
                    <DropDownMenu
                        key={index}
                        name={subOption.name}
                        children={subOption.children}
                        icon={subOption.icon}
                        backgroundColor={green}
                    />
                );
            }
        });
    }

    return (
        <List
            key={Math.random()}
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.root}
        >
            {handler(menuItems.data)}
        </List>
    );
}
