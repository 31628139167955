import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { AGENT_ADD, LOGOUT } from 'ConstComponents';
import Box from '@material-ui/core/Box';
import { MaterialTextField } from 'Components/Inputes/FormikMaterialInput';
import { MaterialNumberField } from 'Components/Inputes/FormikMaterialNumberInput';
import { CustomeAlert } from 'Components/Alert/Alert';
import { useHistory } from 'react-router';
import { Grid, Paper, Button } from '@material-ui/core';

import 'react-datepicker/dist/react-datepicker.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import { commonStyle } from 'Components/CustomMaterialStyleSheets/CommonStyleSheet';
import { MaterialDatePickerField } from 'Components/Inputes/MaterialDatePickerField';
import {
    PLEASE_PROVIDE_VALID,
    PLEASE_SELECT_VALID,
    MESSAGE_CLOSE_AFTER
} from 'Components/Helper/Messages';

export const AgentAdd = () => {
    const classes = commonStyle();
    const history = useHistory();
    const [responseMessage, setResponseMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [loading, setLoading] = useState(false);

    var ModelObj = {
        AgentName: '',
        AgentMobile: '',
        AgentPanNumber: '',
        AgentDOJ: null,
        AgentAddress: '',
        AgentPanPhoto: '',
        AgentAdharcardPhoto: ''
    };

    const submithandle = async (values, { setSubmitting, resetForm }) => {
        var requestObj = JSON.stringify(values);
        setIsError(false);

        setLoading(true);

        var response = await AGENT_ADD(requestObj);

        if (!response.isAuthorized) LOGOUT();

        setSeverity('success');
        setIsError(true);
        setResponseMessage(response.data);
        if (!response.status) {
            setLoading(false);
            setSeverity('error');
            return;
        }

        resetForm(ModelObj);

        setTimeout(() => {
            history.push('/Agent/Index');
        }, MESSAGE_CLOSE_AFTER);
    };

    const validationSchema = Yup.object({
        AgentName: Yup.string().required(PLEASE_PROVIDE_VALID + 'agent name'),

        AgentMobile: Yup.string()
            .length(10, PLEASE_PROVIDE_VALID + 'mobile number')
            .required(PLEASE_PROVIDE_VALID + 'mobile number'),

        AgentPanNumber: Yup.string()
            .length(15, PLEASE_PROVIDE_VALID + 'pan number')
            .required(PLEASE_PROVIDE_VALID + 'pan number'),

        AgentDOJ: Yup.date()
            .nullable(PLEASE_SELECT_VALID + 'date')
            .max(new Date(), PLEASE_SELECT_VALID + 'date')
            .required(PLEASE_SELECT_VALID + 'date'),

        AgentAddress: Yup.string().required(PLEASE_PROVIDE_VALID + 'address')
    });

    return (
        <>
            {isError ? (
                <CustomeAlert
                    isOpen={isError}
                    message={responseMessage}
                    severity={severity}
                    onCloseCallback={alertState => {
                        setIsError(alertState);
                    }}
                />
            ) : null}
            <Formik
                enableReinitialize={true}
                initialValues={ModelObj}
                validationSchema={validationSchema}
                onSubmit={submithandle}
                resetForm={ModelObj}
                render={formProps => {
                    return (
                        <Form>
                            <h2 className={classes.PaperHead_h2}>
                                Agent Details
                            </h2>

                            <Grid item xs={12}>
                                <Paper className={classes.PaperStyle}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <MaterialTextField
                                                label="Agent Name"
                                                name="AgentName"
                                                type="text"
                                                title="Agent Name"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <MaterialNumberField
                                                label="Mobile Number"
                                                name="AgentMobile"
                                                type="text"
                                                length={10}
                                                title="Mobile Number"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <MaterialTextField
                                                label="Address"
                                                name="AgentAddress"
                                                type="text"
                                                title="Address"
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>

                            <Grid item xs={12}>
                                <Paper className={classes.PaperStyle}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <MaterialDatePickerField
                                                name="AgentDOJ"
                                                label="Agent DOJ"
                                                maxDate={new Date()}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={4}>
                                            <MaterialTextField
                                                label="Agent Pan Number"
                                                name="AgentPanNumber"
                                                type="text"
                                                length={15}
                                                title="Agent Pan Number"
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>

                            <Grid item xs={12}>
                                <Paper className={classes.PaperStyle}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <MaterialTextField
                                                label="Pan Photo"
                                                name="AgentPanPhoto"
                                                type="text"
                                                title="Agent Pan Photo"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <MaterialTextField
                                                label="Adhar card Photo"
                                                name="AgentPanPhoto"
                                                type="text"
                                                title="Agent Pan Photo"
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>

                            <Grid className={classes.footerGrid}>
                                <Box className={classes.flexReverse}>
                                    <div className={classes.SubmitWrapper}>
                                        <Button
                                            type="submit"
                                            title="Register"
                                            className={
                                                classes.CustomPrimaryButtonStyle
                                            }
                                            disabled={loading}
                                        >
                                            Save
                                        </Button>
                                        {loading && (
                                            <CircularProgress
                                                size={24}
                                                className={
                                                    classes.buttonProgress
                                                }
                                            />
                                        )}
                                    </div>

                                    <Button
                                        type="reset"
                                        title="Reset"
                                        className={
                                            classes.CustomDangerButtonStyle
                                        }
                                    >
                                        Reset
                                    </Button>
                                </Box>
                            </Grid>
                        </Form>
                    );
                }}
            />
        </>
    );
};
