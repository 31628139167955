import React, { useState, useEffect } from 'react';

import {
    Card,
    CardContent,
    CardMedia,
    Typography,
    Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSpring, animated } from 'react-spring';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
    card: {
        width: '100%',
        maxWidth: 300,
        borderRadius: spacing(2), // 16px
        boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        padding: 5,

        [breakpoints.up('sm')]: {
            textAlign: 'left',
            flexDirection: 'row-reverse'
        }
    },

    overline: {
        lineHeight: 2,
        color: '#000000',
        fontWeight: 'bold',
        fontSize: '0.625rem',
        opacity: 0.7
    },
    heading: {
        fontWeight: '900',
        color: '#000000',
        letterSpacing: 0.5
    },
    media: {
        width: 151,
        borderRadius: spacing(2)
    }
}));

export const DashBoardCard = ({ image, title, value }) => {
    const calc = (x, y) => [
        -(y - window.innerHeight / 2) / 40,
        (x - window.innerWidth / 2) / 40,
        1.1
    ];
    const trans = (x, y, s) =>
        `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

    const styles = useStyles();
    const [props, set] = useSpring(() => ({
        xys: [0, 0, 1],
        config: { mass: 5, tension: 350, friction: 40 }
    }));

    return (
        <Grid item xs={12} sm={12} md={4}>
            <animated.div
                className="card"
                onMouseMove={({ clientX: x, clientY: y }) =>
                    set({ xys: calc(x, y) })
                }
                onMouseLeave={() => set({ xys: [0, 0, 1] })}
                style={{ transform: props.xys.interpolate(trans) }}
            >
                <Card className={styles.card}>
                    <CardMedia
                        className={styles.media}
                        image={image}
                        title={title}
                    />
                    <CardContent>
                        <Typography
                            className={styles.overline}
                            variant={'overline'}
                        >
                            {title}
                        </Typography>
                        <Typography
                            className={styles.heading}
                            variant={'h6'}
                            gutterBottom
                        >
                            {value}
                        </Typography>
                    </CardContent>
                </Card>
            </animated.div>
        </Grid>
    );
};
