import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { commonStyle } from 'Components/CustomMaterialStyleSheets/CommonStyleSheet';
import { Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default function ReactTable(props) {
    const classes = commonStyle();
    const [mainColumns, setMainColumns] = useState([]);

    useEffect(() => {
        createColumns();
    }, [props.columns.length]);

    const createColumns = () => {
        var columns = [];

        props.columns.map((column, index) => {
            columns.push({
                ...column,
                ...(column.numeric
                    ? {
                          options: {
                              customBodyRender: (
                                  value,
                                  tableMeta,
                                  updateValue
                              ) => {
                                  return (
                                      <div
                                          className={classes.numbericRightAlign}
                                          style={{
                                              width: column.textwrapwidth
                                                  ? column.textwrapwidth
                                                  : 100
                                          }}
                                      >
                                          {value}
                                      </div>
                                  );
                              }
                          }
                      }
                    : null),
                ...(column.srno
                    ? {
                          options: {
                              filter: false,
                              sort: false,
                              customBodyRender: (
                                  value,
                                  tableMeta,
                                  updateValue
                              ) => {
                                  const rowId = tableMeta.rowIndex;
                                  return <div>{rowId + 1}</div>;
                              }
                          }
                      }
                    : null),
                ...(column.edit
                    ? {
                          options: {
                              filter: false,
                              sort: false,
                              customBodyRender: (
                                  value,
                                  tableMeta,
                                  updateValue
                              ) => {
                                  return (
                                      <EditIcon
                                          color="primary"
                                          onClick={event => {
                                              column.callback(value);
                                          }}
                                      />
                                  );
                              }
                          }
                      }
                    : null),
                ...(column.delete
                    ? {
                          options: {
                              filter: false,
                              sort: false,
                              download: false,
                              customBodyRender: (
                                  value,
                                  tableMeta,
                                  updateValue
                              ) => {
                                  return (
                                      <DeleteIcon
                                          color="secondary"
                                          variant="contained"
                                          onClick={event => {
                                              column.callback(value);
                                          }}
                                      />
                                  );
                              }
                          }
                      }
                    : null),
                ...(column.activate
                    ? {
                          options: {
                              filter: false,
                              sort: false,
                              download: false,
                              customBodyRender: (
                                  value,
                                  tableMeta,
                                  updateValue
                              ) => {
                                  const id =
                                      tableMeta.rowData[column.primaryKeyIndex];
                                  return (
                                      <FormControlLabel
                                          value={value ? 'Yes' : 'No'}
                                          control={
                                              <Switch
                                                  color="primary"
                                                  checked={value}
                                                  value={value ? 'Yes' : 'No'}
                                              />
                                          }
                                          onChange={event => {
                                              var newValue =
                                                  event.target.value === 'Yes'
                                                      ? false
                                                      : true;
                                              updateValue(newValue);
                                              column.callback(id, newValue);
                                          }}
                                      />
                                  );
                              }
                          }
                      }
                    : null),
                ...(column.textwrap
                    ? {
                          options: {
                              customBodyRender: (
                                  value,
                                  tableMeta,
                                  updateValue
                              ) => {
                                  return (
                                      <Tooltip title={value}>
                                          <div
                                              style={{
                                                  width: column.textwrapwidth
                                                      ? column.textwrapwidth
                                                      : 100,
                                                  whiteSpace: 'nowrap'
                                              }}
                                          >
                                              <Box
                                                  component="div"
                                                  my={2}
                                                  textOverflow="ellipsis"
                                                  overflow="hidden"
                                              >
                                                  {value}
                                              </Box>
                                          </div>
                                      </Tooltip>
                                  );
                              }
                          }
                      }
                    : null)
            });
        });

        setMainColumns(columns);
    };

    const getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MUIDataTableBodyCell: {
                    root: {
                        paddingLeft: '20px',
                        paddingRight: '0px',
                        paddingTop: '0px',
                        paddingBottom: '0px'
                    },
                    stackedCommon: {
                        '@media (max-width:959.95px)': {
                            height: '100%'
                        }
                    }
                },
                MUIDataTableHeadCell: {
                    fixedHeaderCommon: {
                        backgroundColor: '#ffffff',
                        fontWeight: 'bold',
                        borderBottom: 'none'
                    }
                },
                MUIDataTableBodyRow: {
                    root: {
                        '&:nth-child(odd)': {
                            backgroundColor: '#e0e0e0'
                        }
                    }
                },
                MuiTableCell: {
                    root: {
                        lineHeight: 2.7
                    }
                },
                MuiSvgIcon: {
                    root: {
                        verticalAlign: 'middle'
                    }
                }
            }
        });

    return (
        <MuiThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
                title={props.title}
                data={props.List}
                columns={mainColumns}
                options={options}
                className="table nowrap"
                style={{
                    marginTop: '5px'
                }}
            />
        </MuiThemeProvider>
    );
}

const options = {
    filterType: 'dropdown',
    selectableRows: 'none',
    rowsPerPageOptions: [10, 50, 100],
    print: false,
    download: true,
    //search: false,
    //filter: false,
    viewColumns: false,
    responsive: 'stacked',
    fixedHeaderOptions: {
        xAxis: false,
        yAxis: true
    },
    pagination: {
        next: 'Next Page',
        previous: 'Previous Page',
        rowsPerPage: 'Rows per page:',
        displayRows: 'of'
    }
};
