import React from 'react';
import { useField } from 'formik';
import { TextField, FormControl } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    MaterialTextFieldStyle: {
        marginTop: '0px',
        height: '40px'
    }
}));

export const MaterialNumberField = ({ label, ...props }) => {
    const classes = useStyles();

    const [field, meta, helpers] = useField(props);

    //&& meta.value.length < props.length

    field.onChange = e => {
        const { value } = e.target;

        const reg = /^-?[0-9]*(\.[0-9]*)?$/;

        if (
            (!isNaN(value) && reg.test(value)) ||
            value === '' ||
            value === '-'
        ) {
            meta.value = value;
            helpers.setValue(value);
        }
    };

    return (
        <FormControl fullWidth>
            <TextField
                className={classes.MaterialTextFieldStyle}
                label={label}
                type={props.type}
                error={meta.touched && meta.error ? true : false}
                helperText={meta.touched && meta.error ? meta.error : null}
                inputProps={{
                    maxLength: props.length ? props.length : null
                }}
                {...field}
                {...props}
            />
        </FormControl>
    );
};
