import React, { memo } from 'react';
import { Route } from 'react-router-dom';

const OuterLayout = ({ children }) => <>{children}</>;

const OuterLayoutRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={matchProps => (
                <OuterLayout>
                    <Component {...matchProps} />
                </OuterLayout>
            )}
        />
    );
};

export default memo(OuterLayoutRoute);
