import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    PACKAGE_UPDATE,
    PACKAGE_SELECT_PACKAGE,
    LOGOUT
} from 'ConstComponents';
import Box from '@material-ui/core/Box';
import { MaterialTextField } from 'Components/Inputes/FormikMaterialInput';
import { CustomeAlert } from 'Components/Alert/Alert';
import { Container, Grid, Paper, Button } from '@material-ui/core';
import { useHistory } from 'react-router';
import { commonStyle } from 'Components/CustomMaterialStyleSheets/CommonStyleSheet';
import { MaterialNumberField } from 'Components/Inputes/FormikMaterialNumberInput';
import { MESSAGE_CLOSE_AFTER } from 'Components/Helper/Messages';

export const PackageEdit = () => {
    const classes = commonStyle();
    const history = useHistory();
    const [responseMessage, setResponseMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [loadingOpen, setLoadingOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [PackageObj, setPackageObj] = useState({
        PackageID: 0,
        PackageName: '',
        Price: ''
    });

    const selectPackage = async () => {
        let URL = window.location.pathname;
        var res = URL.split('/');

        let PackageID = res[res.length - 1];

        let JSONObj = {
            PackageID: PackageID
        };

        var request = JSON.stringify(JSONObj);
        setIsError(false);
        setLoadingOpen(true);
        const response = await PACKAGE_SELECT_PACKAGE(request);
        setLoadingOpen(false);
        setSeverity('success');

        if (!response.isAuthorized) {
            LOGOUT();
        }
        var data = JSON.parse(response.data);

        if (!response.status) {
            setSeverity('error');
            return;
        }

        PackageObj.PackageName = data.PackageName;
        setPackageObj({
            PackageID: data.PackageID,
            PackageName: data.PackageName,
            Price: data.Price
        });
    };

    useEffect(() => {
        selectPackage();
    }, []);

    const submithandle = async (values, { setSubmitting, resetForm }) => {
        if (loading) {
            setLoading(false);
            return;
        }
        var requestObj = JSON.stringify(values);
        setIsError(false);
        setLoading(true);

        var response = await PACKAGE_UPDATE(requestObj);

        if (!response.isAuthorized) LOGOUT();

        setIsError(true);
        setSeverity('success');
        setResponseMessage(response.data);

        if (!response.status) {
            setSeverity('error');
            return;
        }

        resetForm(PackageObj);

        setTimeout(() => {
            history.push('/Package/Index');
        }, MESSAGE_CLOSE_AFTER);
    };

    const pleaseProvideValid = 'Please provide valid ';

    const RegistrationScheama = Yup.object({
        PackageName: Yup.string().required(pleaseProvideValid + 'package name'),
        Price: Yup.number()
            .required(pleaseProvideValid + 'price')
            .moreThan(0, pleaseProvideValid + 'price')
            .lessThan(5001, pleaseProvideValid + 'price')
    });
    const resethandle = resetForm => {};
    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={PackageObj}
                validationSchema={RegistrationScheama}
                onSubmit={submithandle}
                onReset={resethandle}
                render={formProps => {
                    return (
                        <Form>
                            {isError ? (
                                <div>
                                    <CustomeAlert
                                        isOpen={isError}
                                        severity={severity}
                                        message={responseMessage}
                                    />
                                </div>
                            ) : null}

                            <h2 className={classes.PaperHead_h2}>
                                Package Details
                            </h2>
                            <Grid item xs={12}>
                                <Paper className={classes.PaperStyle}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={4} item>
                                            <MaterialTextField
                                                label="Package"
                                                name="PackageName"
                                                type="text"
                                                title="Package"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} item>
                                            <MaterialNumberField
                                                label="Price"
                                                name="Price"
                                                type="text"
                                                title="Price"
                                                length="4"
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>

                            <Grid className={classes.footerGrid}>
                                <Box className={classes.flexReverse}>
                                    <div className={classes.SubmitWrapper}>
                                        <Button
                                            type="submit"
                                            title="Submit"
                                            className={
                                                classes.CustomPrimaryButtonStyle
                                            }
                                            disabled={loading}
                                        >
                                            Update
                                        </Button>
                                        {loading && (
                                            <CircularProgress
                                                size={24}
                                                className={
                                                    classes.buttonProgress
                                                }
                                            />
                                        )}
                                    </div>

                                    <Button
                                        type="reset"
                                        title="Reset"
                                        className={
                                            classes.CustomDangerButtonStyle
                                        }
                                    >
                                        Reset
                                    </Button>
                                </Box>
                            </Grid>
                        </Form>
                    );
                }}
            />
        </>
    );
};
