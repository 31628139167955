import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Box from '@material-ui/core/Box';
import {
    LOGOUT,
    PAYMENTMODE_SELECT_ALL,
    CUSTOMER_SELECT_FOR_DROPDOWN,
    CUSTOMER_SELECT_CUSTOMER,
    LAST_TEN_TRANSACTION,
    TRANSACTION_INSERT
} from 'ConstComponents';

import { MaterialTextField } from 'Components/Inputes/FormikMaterialInput';
import { MaterialSelect } from 'Components/Inputes/FormikMaterialSelect';
import { MaterialNumberField } from 'Components/Inputes/FormikMaterialNumberInput';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MaterialDatePickerField } from 'Components/Inputes/MaterialDatePickerField';

import ReactTable from 'Components/ReactTable/ReactTable';
import { MenuItem, Grid, Paper, Button, TextField } from '@material-ui/core';

import { commonStyle } from 'Components/CustomMaterialStyleSheets/CommonStyleSheet';

import CircularProgress from '@material-ui/core/CircularProgress';
import { CustomeAlert } from 'Components/Alert/Alert';

import {
    PLEASE_SELECT_VALID,
    PLEASE_PROVIDE_VALID
} from 'Components/Helper/Messages';
export const PaymentAdd = () => {
    const classes = commonStyle();

    const [paymentModeList, setpaymentModeList] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [transactions, setTransactions] = useState([]);
    const [mainColumns, setMainColumns] = useState([]);

    const [CustomerObj, setCustomerObj] = useState({
        Customer: {
            CustomerID: 0,
            Mobile: '',
            STB: '',
            Address: '',
            PackageName: '',
            Name: ''
        },
        PaymentModeID: 0,
        Remark: '',
        Amount: '',
        strTxnDate: new Date()
    });

    const selectCustomerByID = async values => {
        const response = await CUSTOMER_SELECT_CUSTOMER(values);

        if (!response.isAuthorized) {
            LOGOUT();
        }
        var data = JSON.parse(response.data);

        setCustomerObj({
            ...CustomerObj,
            Customer: {
                CustomerID: data.CustomerID,
                Mobile: data.CustomerMobile,
                STB: data.SetupBoxNumber,
                Address: data.Address,
                PackageName: data.PackageName,
                Name: data.CustomerName
            }
        });
    };

    const selectPaymentMode = async values => {
        setLoading(true);
        var request = JSON.stringify(values);
        setLoading(false);
        const packageResponse = await PAYMENTMODE_SELECT_ALL(request);

        if (!packageResponse.isAuthorized) LOGOUT();
        if (!packageResponse.status) {
            setIsError(true);
            setSeverity('error');
            return;
        }

        var data = JSON.parse(packageResponse.data);
        setpaymentModeList(data);
    };

    const SelectCustomer = async values => {
        setLoading(true);
        var request = JSON.stringify(values);
        setLoading(false);
        const customerResponse = await CUSTOMER_SELECT_FOR_DROPDOWN(request);

        if (!customerResponse.isAuthorized) LOGOUT();
        if (!customerResponse.status) {
            setIsError(true);
            setSeverity('error');
            return;
        }
        var data = JSON.parse(customerResponse.data);

        setCustomerList(data);
    };

    const resetCustomer = () => {
        setCustomerObj({
            ...CustomerObj,
            Customer: {
                CustomerID: 0,
                Mobile: '',
                STB: '',
                Address: '',
                PackageName: '',
                Name: ''
            },
            PaymentModeID: 0,
            Remark: '',
            Amount: '',
            strTxnDate: new Date()
        });
    };

    const OnRoleChange = (event, value) => {
        if (value === null || value === undefined) {
            resetCustomer();
            return;
        }

        var CustomerID = value.CustomerID; //event.target.value;

        if (
            CustomerID === null ||
            CustomerID === undefined ||
            CustomerID === 0
        ) {
            resetCustomer();
            return;
        }

        var requestObj = {
            CustomerID: CustomerID
        };

        setTransactions([]);
        var request = JSON.stringify(requestObj);

        selectCustomerByID(request);
        selectLastTenTx(request);
    };

    const selectLastTenTx = async request => {
        const response = await LAST_TEN_TRANSACTION(request);

        if (!response.isAuthorized) LOGOUT();

        if (!response.status) {
            setIsError(true);
            setResponseMessage(response.data);
            setSeverity('error');
            return;
        }

        var data = JSON.parse(response.data);
        setTransactions(data);
    };

    useEffect(() => {
        selectPaymentMode();
        SelectCustomer();
        createColumns();
    }, []);

    const submithandle = async (values, { setSubmitting, resetForm }) => {
        var TransactionObj = {
            CustomerID: values.Customer.CustomerID,
            Amount: values.Amount,
            PaymentModeID: values.PaymentModeID,
            Remark: values.Remark,
            strTxnDate: values.strTxnDate
        };

        var requestObj = JSON.stringify(TransactionObj);
        setIsError(false);
        setLoading(true);
        var response = await TRANSACTION_INSERT(requestObj);

        if (!response.isAuthorized) LOGOUT();

        setIsError(true);
        setSeverity('success');
        setResponseMessage(response.data);
        if (!response.status) {
            setSeverity('error');
            setLoading(false);
            return;
        }

        customReset();
        // setTimeout(() => {
        //     history.push('/Payment/Index');
        // }, MESSAGE_CLOSE_AFTER);
    };

    const validationSchema = Yup.object({
        Customer: Yup.object().shape({
            CustomerID: Yup.string()
                .trim()
                .notOneOf(['0'], PLEASE_SELECT_VALID + 'customer')
                .required(PLEASE_SELECT_VALID + 'customer'),
            Mobile: Yup.string()
                .trim()
                .required(PLEASE_SELECT_VALID + 'customer')
        }),

        PaymentModeID: Yup.string()
            .trim()
            .notOneOf(['0'], PLEASE_SELECT_VALID + 'payment mode')
            .required(PLEASE_SELECT_VALID + 'payment mode'),

        Remark: Yup.string()
            .trim()
            //.required(PLEASE_PROVIDE_VALID + 'remark')
            .max(50, 'Please enter only max 50 char.'),
        Amount: Yup.number()
            .moreThan(0, PLEASE_PROVIDE_VALID + 'amount')
            .required(PLEASE_PROVIDE_VALID + 'amount')
    });

    const customReset = () => {
        setTransactions([]);

        setLoading(false);
        setCustomerObj({
            Customer: {
                CustomerID: 0,
                Mobile: '',
                STB: '',
                Address: '',
                PackageName: '',
                Name: ''
            },
            PaymentModeID: 0,
            Remark: '',
            Amount: '',
            strTxnDate: new Date()
        });
    };

    const createColumns = () => {
        var columns = [];

        columns.push({
            label: '#',
            name: 'DOC',
            srno: true
        });

        columns.push({
            label: 'Payment Date',
            name: 'DOC'
        });

        columns.push({
            label: 'Amount',
            name: 'Amount',
            numeric: true,
            width: 100
        });
        columns.push({
            label: 'Pay Mode',
            name: 'PaymentMode'
        });
        columns.push({
            label: 'ReceivedBy',
            name: 'ReceivedBy'
        });
        columns.push({
            label: 'Package',
            name: 'PackageName'
        });
        columns.push({
            label: 'Remark',
            name: 'Remark',
            textwrapwidth: 100,
            textwrap: true
        });

        setMainColumns(columns);
    };

    return (
        <>
            {isError ? (
                <CustomeAlert
                    isOpen={isError}
                    message={responseMessage}
                    severity={severity}
                    onCloseCallback={alertState => {
                        setIsError(alertState);
                    }}
                />
            ) : null}
            <Formik
                enableReinitialize={true}
                initialValues={CustomerObj}
                validationSchema={validationSchema}
                onSubmit={submithandle}
                resetForm={CustomerObj}
                render={formProps => {
                    return (
                        <Form>
                            <h2 className={classes.PaperHead_h2}>
                                Payment Recieve Detail
                            </h2>

                            <Grid item xs={12}>
                                <Paper className={classes.PaperStyle}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={8}>
                                            <Autocomplete
                                                id="Customer.CustomerID"
                                                name="Customer.CustomerID"
                                                options={customerList}
                                                onChange={OnRoleChange}
                                                getOptionLabel={option =>
                                                    option.Name
                                                        ? option.Name +
                                                          ' (' +
                                                          option.STB +
                                                          ') [' +
                                                          option.Mobile +
                                                          ']'
                                                        : null
                                                }
                                                value={
                                                    formProps.values.Customer
                                                }
                                                getOptionSelected={(
                                                    option,
                                                    value
                                                ) =>
                                                    option.CustomerID ===
                                                    value.CustomerID
                                                }
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        label="Select Customer"
                                                        margin="normal"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Paper
                                                className={classes.PaperStyle}
                                            >
                                                <Grid container spacing={2}>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={6}
                                                    >
                                                        <MaterialTextField
                                                            label="STB No"
                                                            name="Customer.STB"
                                                            type="TextArea"
                                                            icon="shop"
                                                            disabled
                                                            title="STB No"
                                                        />
                                                    </Grid>

                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={6}
                                                    >
                                                        <MaterialTextField
                                                            label="Mobile"
                                                            name="Customer.Mobile"
                                                            type="TextArea"
                                                            icon="shop"
                                                            disabled
                                                            title="Mobile"
                                                        />
                                                    </Grid>

                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={6}
                                                    >
                                                        <MaterialTextField
                                                            label="Address"
                                                            name="Customer.Address"
                                                            type="TextArea"
                                                            icon="shop"
                                                            disabled
                                                            title="Address"
                                                        />
                                                    </Grid>

                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={6}
                                                    >
                                                        <MaterialTextField
                                                            label="Package"
                                                            name="Customer.PackageName"
                                                            type="TextArea"
                                                            icon="shop"
                                                            disabled
                                                            title="Package"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>

                            <h2 className={classes.PaperHead_h2}>Payment</h2>
                            <Grid item xs={12}>
                                <Paper className={classes.PaperStyle}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <MaterialSelect
                                                label="Payment Mode"
                                                name="PaymentModeID"
                                                title="Payment Mode"
                                            >
                                                <MenuItem key="0" value="0">
                                                    Select Pay Mode
                                                </MenuItem>
                                                {paymentModeList.map(item => (
                                                    <MenuItem
                                                        key={item.PaymentModeID}
                                                        value={
                                                            item.PaymentModeID
                                                        }
                                                    >
                                                        {item.PaymentMode}
                                                    </MenuItem>
                                                ))}
                                            </MaterialSelect>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <MaterialNumberField
                                                label="Paid Amount"
                                                name="Amount"
                                                type="text"
                                                length={5}
                                                title="Paid Amount"
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={8}>
                                            <MaterialTextField
                                                label="Remark"
                                                name="Remark"
                                                type="TextArea"
                                                icon="shop"
                                                length={50}
                                                title="Remark"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <MaterialDatePickerField
                                                name="strTxnDate"
                                                label="Date"
                                                maxDate={new Date()}
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>

                            <Grid className={classes.footerGrid}>
                                <Box className={classes.flexReverse}>
                                    <div className={classes.SubmitWrapper}>
                                        <Button
                                            type="submit"
                                            title="submit"
                                            className={
                                                classes.CustomPrimaryButtonStyle
                                            }
                                            disabled={loading}
                                        >
                                            Payment
                                        </Button>
                                        {loading && (
                                            <CircularProgress
                                                size={24}
                                                className={
                                                    classes.buttonProgress
                                                }
                                            />
                                        )}
                                    </div>

                                    <Button
                                        type="reset"
                                        title="Reset"
                                        className={
                                            classes.CustomDangerButtonStyle
                                        }
                                    >
                                        Reset
                                    </Button>
                                </Box>
                            </Grid>
                        </Form>
                    );
                }}
            />

            <ReactTable List={transactions} columns={mainColumns} />
        </>
    );
};
