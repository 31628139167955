import { PostAPI } from 'Components/Helper/Helpers';

export const Select = async request => {
    return PostAPI(request, 'Package/Select');
};
export const Add = async request => {
    return PostAPI(request, 'Package/Insert');
};
export const Delete = async request => {
    return PostAPI(request, 'Package/Delete');
};
export const SelectPackage = async request => {
    return PostAPI(request, 'Package/SelectPackageByID');
};
export const Activate = async request => {
    return PostAPI(request, 'Package/Activate');
};

export const Update = async request => {
    return PostAPI(request, 'Package/Update');
};
