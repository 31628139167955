import React, { useState, useEffect } from 'react';
import { Container, Grid, Button } from '@material-ui/core';
import { CustomeAlert } from 'Components/Alert/Alert';
import { LOGOUT, LOCATION_DELETE, LOCATION_SELECT_ALL } from 'ConstComponents';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ReactTable from 'Components/ReactTable/ReactTable';
import Backdrop from '@material-ui/core/Backdrop';
import { useHistory } from 'react-router';
import Loader from 'Components/Loader/Loader';
import Box from '@material-ui/core/Box';
import { commonStyle } from 'Components/CustomMaterialStyleSheets/CommonStyleSheet';
import { ConfirmAlert } from 'Components/Alert/ConfirmAlert';

export const Locations = () => {
    const classes = commonStyle();
    const history = useHistory();
    const [locations, setLocations] = useState([]);
    const [mainColumns, setMainColumns] = useState([]);
    const [loadingOpen, setLoadingOpen] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [confirmAlertRequest, setConfirmAlertRequest] = useState({});

    const onClick = item => {
        var RequestObj = { LocationID: item };
        setIsAlertOpen(true);
        setConfirmAlertRequest(RequestObj);
    };

    const selectLocations = async () => {
        setLoadingOpen(true);
        const response = await LOCATION_SELECT_ALL();
        setLoadingOpen(false);

        if (!response.isAuthorized) LOGOUT();

        if (!response.status) {
            setIsError(true);
            setResponseMessage(response.data);
            setSeverity('error');
            return;
        }

        var data = JSON.parse(response.data);
        setLocations(data);
    };

    useEffect(() => {
        selectLocations();
        createColumns();
    }, []);

    const Delete = async request => {
        setIsError(false);
        setLoadingOpen(true);
        var jsonReq = JSON.stringify(request);
        const response = await LOCATION_DELETE(jsonReq);
        setLoadingOpen(false);
        if (!response.isAuthorized) LOGOUT();
        setSeverity('success');
        setResponseMessage(response.data);

        if (!response.status) setSeverity('error');

        setIsError(true);
        selectLocations();
    };
    const EditLocation = async request => {
        history.push('/Location/Edit/' + request);
    };

    const createColumns = () => {
        var columns = [];

        columns.push({
            label: '#',
            name: 'LocationID',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <span>{tableMeta.rowIndex + 1}</span>;
                }
            }
        });

        columns.push({
            label: 'Location',
            name: 'Location'
        });

        columns.push({
            label: 'Edit',
            name: 'LocationID',
            options: {
                filter: false,
                sort: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <EditIcon
                            variant="contained"
                            color="primary"
                            onClick={event => {
                                EditLocation(value);
                            }}
                        />
                    );
                }
            }
        });

        columns.push({
            label: 'Delete ',
            name: 'LocationID',

            options: {
                filter: false,
                sort: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <DeleteIcon
                            color="secondary"
                            variant="contained"
                            onClick={event => {
                                onClick(value);
                            }}
                        />
                    );
                }
            }
        });

        setMainColumns(columns);
    };

    return (
        <>
            {isError ? (
                <CustomeAlert
                    isOpen={isError}
                    message={responseMessage}
                    severity={severity}
                    onCloseCallback={alertState => {
                        setIsError(alertState);
                    }}
                />
            ) : null}

            {isAlertOpen ? (
                <ConfirmAlert
                    isOpen={isAlertOpen}
                    title="Delete Confirmation"
                    message="Are you sure to delete this location."
                    request={confirmAlertRequest}
                    positiveButtonClick={request => {
                        setIsAlertOpen(false);
                        Delete(request);
                    }}
                    negativeButtonClick={() => {
                        setIsAlertOpen(false);
                    }}
                />
            ) : null}

            <Backdrop className={classes.backdrop} open={loadingOpen}>
                <Loader />
            </Backdrop>

            <Grid style={{ marginBottom: '5px', marginTop: '5px' }}>
                <Box display="flex" flexDirection="row-reverse">
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.CustomPrimaryButtonStyle}
                        href="/Location/Add"
                    >
                        New Location
                    </Button>
                </Box>
            </Grid>

            <ReactTable List={locations} columns={mainColumns} />
        </>
    );
};
