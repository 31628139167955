import React, { useState, useRef, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { USER_LOGIN, COPYRIGHT } from 'ConstComponents';

import { MaterialTextField } from 'Components/Inputes/FormikMaterialInput';
import { MaterialNumberField } from 'Components/Inputes/FormikMaterialNumberInput';
import { CustomeAlert } from 'Components/Alert/Alert';
import { Container, Paper, Button, Grid, FormControl } from '@material-ui/core';
import { textToSpeech } from 'Components/Helper/Helpers';
import { makeStyles } from '@material-ui/core/styles';

import { ForgotPassword } from './ForgotPassword';

const useStyles = makeStyles(theme => ({
    login_container: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },

    login_form_wrapper: {
        padding: '2em',
        flexDirection: 'column'
    },

    login_form: {
        display: 'flex',
        flexDirection: 'column'
    },
    login_title: {
        fontFamily: 'Raleway-Medium',
        fontSize: '20px',
        lineHeight: '1.2',
        textAlign: 'center',
        textTransform: 'uppercase'
    },
    customButton: {
        marginTop: '2px',
        alignItems: 'right'
    }
}));

export const Login = () => {
    const classes = useStyles();
    const txtUserNameRef = useRef(null);
    const [loadingOpen, setLoadingOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [severity, setSeverity] = useState('success');

    const ModelObj = {
        UserName: '',
        Password: ''
    };

    const pleaseProvideValid = 'Please provide valid ';

    const validationSchema = Yup.object({
        UserName: Yup.string()
            .length(10, pleaseProvideValid + 'username')
            .required(pleaseProvideValid + 'username'),
        Password: Yup.string()
            .min(4, pleaseProvideValid + 'password')
            .required(pleaseProvideValid + 'password')
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        var request = JSON.stringify(values);
        setIsError(false);
        setLoading(true);
        setLoadingOpen(true);
        const response = await USER_LOGIN(request);
        setLoading(false);
        setLoadingOpen(false);
        if (!response.status) {
            setResponseMessage(response.data);
            setIsError(true);
            setSeverity('error');
            return;
        }

        var ReturnResponse = JSON.parse(response.data);

        textToSpeech('Welcome ' + ReturnResponse.FirmName);

        sessionStorage.setItem('SecureToken', ReturnResponse.SecureToken);
        sessionStorage.setItem('UserID', ReturnResponse.UserID);
        sessionStorage.setItem('FirmName', ReturnResponse.FirmName);
        sessionStorage.setItem('FirmID', ReturnResponse.FirmID);
        sessionStorage.setItem('UserType', ReturnResponse.UserType);
        sessionStorage.setItem('MobileNumber', ReturnResponse.MobileNumber);
        sessionStorage.setItem('UserTypeID', ReturnResponse.UserTypeID);
        sessionStorage.setItem('Authorize', true);
        window.location.href = ReturnResponse.RedirectTo;
        setSubmitting(false);
    };

    useEffect(() => {
        txtUserNameRef.current.focus();
        var element = document.getElementById('mainbody');
        element.classList.add('loginpagebody');
    }, []);

    return (
        <Container className={classes.login_container}>
            <Paper
                elevation={3}
                className={classes.login_form_wrapper}
                square={false}
            >
                {isError ? (
                    <CustomeAlert
                        isOpen={isError}
                        message={responseMessage}
                        severity={severity}
                        closeTime={5}
                        onCloseCallback={alertState => {
                            setIsError(alertState);
                        }}
                    />
                ) : null}

                <Formik
                    initialValues={ModelObj}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    <Form className={classes.login_form}>
                        <FormControl className={classes.login_title}>
                            Please Login
                        </FormControl>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                                <MaterialNumberField
                                    type="text"
                                    length="10"
                                    name="UserName"
                                    label="User Name"
                                    inputRef={txtUserNameRef}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <MaterialTextField
                                    label="Password"
                                    name="Password"
                                    type="password"
                                />
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    item
                                    style={{ marginTop: '2em' }}
                                >
                                    <FormControl fullWidth>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                        >
                                            Login
                                        </Button>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
                <p
                    className="mt-5 mb-3 text-muted "
                    style={{ textAlign: 'center' }}
                >
                    <a onClick={() => setOpen(true)}>Forgot Password</a>
                </p>
            </Paper>

            <COPYRIGHT />

            {open ? (
                <ForgotPassword
                    modalOpen={open}
                    onDismissCallback={modalState => {
                        setOpen(modalState);
                    }}
                />
            ) : null}
        </Container>
    );
};
