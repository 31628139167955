import { PostAPI } from 'Components/Helper/Helpers';

export const Select = async request => {
    return PostAPI(request, 'ReconciliationSetting/Select');
};
export const Insert = async request => {
    return PostAPI(request, 'ReconciliationSetting/Insert');
};

export const Upload = async request => {
    return PostAPI(request, 'ReconciliationDetail/Upload');
};

export const SelectReconciliation = async request => {
    return PostAPI(request, 'ReconciliationDetail/Select');
};
