import React, { useState, useEffect } from 'react';
import { Container, Grid, FormControlLabel, Button } from '@material-ui/core';
import { CustomeAlert } from 'Components/Alert/Alert';
import {
    LOGOUT,
    PAYMENTMODE_ACTIVATE,
    PAYMENTMODE_SELECT_ALL,
    PAYMENTMODE_DELETE
} from 'ConstComponents';
import Switch from '@material-ui/core/Switch';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ReactTable from 'Components/ReactTable/ReactTable';
import { commonStyle } from 'Components/CustomMaterialStyleSheets/CommonStyleSheet';
import Backdrop from '@material-ui/core/Backdrop';
import { useHistory } from 'react-router';
import Loader from 'Components/Loader/Loader';
import Box from '@material-ui/core/Box';
import { ConfirmAlert } from 'Components/Alert/ConfirmAlert';

export const PaymentMode = () => {
    const classes = commonStyle();
    const history = useHistory();
    const [paymentMode, setPaymentMode] = useState([]);
    const [mainColumns, setMainColumns] = useState([]);
    const [loadingOpen, setLoadingOpen] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [confirmAlertRequest, setConfirmAlertRequest] = useState({});

    const onClick = item => {
        var RequestObj = { PaymentModeID: item };
        setIsAlertOpen(true);
        setConfirmAlertRequest(RequestObj);
    };

    const activate = async (item, value) => {
        var jsonObj = {
            PaymentModeID: item,
            IsActive: value
        };
        var request = JSON.stringify(jsonObj);
        setIsError(false);
        setLoadingOpen(true);

        const response = await PAYMENTMODE_ACTIVATE(request);
        setLoadingOpen(false);
        if (!response.isAuthorized) LOGOUT();

        setSeverity('success');
        setResponseMessage(response.data);

        if (!response.status) setSeverity('error');

        setIsError(true);
        selectPaymentMode();
    };

    const selectPaymentMode = async () => {
        setLoadingOpen(true);
        const response = await PAYMENTMODE_SELECT_ALL();
        setLoadingOpen(false);
        if (!response.isAuthorized) LOGOUT();

        if (!response.status) {
            setIsError(true);
            setResponseMessage(response.data);
            setSeverity('error');
            return;
        }

        var data = JSON.parse(response.data);
        setPaymentMode(data);
    };

    useEffect(() => {
        selectPaymentMode();
        createColumns();
    }, []);

    const Delete = async request => {
        setIsError(false);
        setLoadingOpen(true);
        var jsonReq = JSON.stringify(request);
        const response = await PAYMENTMODE_DELETE(jsonReq);
        setLoadingOpen(false);
        if (!response.isAuthorized) LOGOUT();
        setSeverity('success');
        setResponseMessage(response.data);

        if (!response.status) setSeverity('error');

        setIsError(true);
        selectPaymentMode();
    };
    const EditPaymentMode = async request => {
        history.push('/PaymentMode/Edit/' + request);
    };

    const createColumns = () => {
        var columns = [];

        columns.push({
            label: '#',
            name: 'PaymentModeID',
            srno: true
        });

        columns.push({
            label: 'PaymentMode',
            name: 'PaymentMode'
        });

        columns.push({
            label: 'Active',
            name: 'IsActive',
            options: {
                filter: false,
                sort: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const PaymentModeID = tableMeta.rowData[0];
                    return (
                        <FormControlLabel
                            value={value ? 'Yes' : 'No'}
                            control={
                                <Switch
                                    color="primary"
                                    checked={value}
                                    value={value ? 'Yes' : 'No'}
                                />
                            }
                            onChange={event => {
                                var newValue =
                                    event.target.value === 'Yes' ? false : true;
                                updateValue(newValue);
                                activate(PaymentModeID, newValue);
                            }}
                        />
                    );
                }
            }
        });

        columns.push({
            label: 'Edit',
            name: 'PaymentModeID',
            options: {
                filter: false,
                sort: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <EditIcon
                            variant="contained"
                            color="primary"
                            onClick={event => {
                                EditPaymentMode(value);
                            }}
                        />
                    );
                }
            }
        });

        columns.push({
            label: 'Delete ',
            name: 'PaymentModeID',
            options: {
                filter: false,
                sort: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <DeleteIcon
                            color="secondary"
                            variant="contained"
                            onClick={event => {
                                onClick(value);
                            }}
                        />
                    );
                }
            }
        });

        setMainColumns(columns);
    };

    return (
        <>
            {isError ? (
                <CustomeAlert
                    isOpen={isError}
                    message={responseMessage}
                    severity={severity}
                    onCloseCallback={alertState => {
                        setIsError(alertState);
                    }}
                />
            ) : null}

            {isAlertOpen ? (
                <ConfirmAlert
                    isOpen={isAlertOpen}
                    title="Delete Confirmation"
                    message="Are you sure to delete this payment mode."
                    request={confirmAlertRequest}
                    positiveButtonClick={request => {
                        setIsAlertOpen(false);
                        Delete(request);
                    }}
                    negativeButtonClick={() => {
                        setIsAlertOpen(false);
                    }}
                />
            ) : null}

            <Backdrop className={classes.backdrop} open={loadingOpen}>
                <Loader />
            </Backdrop>

            <Grid style={{ marginBottom: '5px', marginTop: '5px' }}>
                <Box display="flex" flexDirection="row-reverse">
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.CustomPrimaryButtonStyle}
                        href="/PaymentMode/Add"
                    >
                        New Payment Mode
                    </Button>
                </Box>
            </Grid>

            <ReactTable List={paymentMode} columns={mainColumns} />
        </>
    );
};
