import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
    PAYMENTMODE_UPDATE,
    PAYMENTMODE_SELECT_PAYMENTMODE,
    LOGOUT
} from 'ConstComponents';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MaterialTextField } from 'Components/Inputes/FormikMaterialInput';
import { Container, Grid, Paper, Button } from '@material-ui/core';
import { useHistory } from 'react-router';
import { commonStyle } from 'Components/CustomMaterialStyleSheets/CommonStyleSheet';
import { CustomeAlert } from 'Components/Alert/Alert';
import { MESSAGE_CLOSE_AFTER } from 'Components/Helper/Messages';

export const PaymentModeEdit = () => {
    const classes = commonStyle();
    const history = useHistory();
    const [responseMessage, setResponseMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [loading, setLoading] = useState(false);
    const [PaymentModeObj, setPaymentModeObj] = useState({
        PaymentModeID: 0,
        PaymentMode: ''
    });

    const selectPaymentMode = async () => {
        let URL = window.location.pathname;
        var res = URL.split('/');

        let PaymentModeID = res[res.length - 1];

        let JSONObj = {
            PaymentModeID: PaymentModeID
        };

        var request = JSON.stringify(JSONObj);
        setIsError(false);
        setLoading(true);
        const response = await PAYMENTMODE_SELECT_PAYMENTMODE(request);
        setLoading(false);
        setSeverity('success');

        if (!response.isAuthorized) {
            LOGOUT();
        }
        var data = JSON.parse(response.data);

        if (!response.status) {
            setSeverity('error');
            return;
        }

        PaymentModeObj.PaymentMode = data.PaymentMode;
        setPaymentModeObj({
            PaymentModeID: data.PaymentModeID,
            PaymentMode: data.PaymentMode
        });
    };

    useEffect(() => {
        selectPaymentMode();
    }, []);

    const submithandle = async (values, { setSubmitting, resetForm }) => {
        if (loading) {
            setLoading(false);
            return;
        }
        var requestObj = JSON.stringify(values);
        setIsError(false);
        setLoading(true);

        var response = await PAYMENTMODE_UPDATE(requestObj);

        if (!response.isAuthorized) LOGOUT();

        setIsError(true);

        setSeverity('success');
        setResponseMessage(response.data);

        if (!response.status) {
            setSeverity('error');
            setLoading(false);
            return;
        }

        resetForm(PaymentModeObj);
        setTimeout(() => {
            history.push('/PaymentMode/Index');
        }, MESSAGE_CLOSE_AFTER);
    };

    const PaymentModeScheama = Yup.object({
        PaymentMode: Yup.string().required('Please enter valid payment mode.')
    });

    return (
        <>
            {isError ? (
                <CustomeAlert
                    isOpen={isError}
                    message={responseMessage}
                    severity={severity}
                    onCloseCallback={alertState => {
                        setIsError(alertState);
                    }}
                />
            ) : null}
            <Formik
                enableReinitialize={true}
                initialValues={PaymentModeObj}
                validationSchema={PaymentModeScheama}
                onSubmit={submithandle}
                resetForm={PaymentModeObj}
                render={formProps => {
                    return (
                        <Form>
                            <h2 className={classes.PaperHead_h2}>
                                Payment Mode Details
                            </h2>
                            <Grid item xs={12}>
                                <Paper className={classes.PaperStyle}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={4} item>
                                            <MaterialTextField
                                                label="PaymentMode"
                                                name="PaymentMode"
                                                type="text"
                                                title="Payment Mode"
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>

                            <Grid className={classes.footerGrid}>
                                <Box className={classes.flexReverse}>
                                    <div className={classes.SubmitWrapper}>
                                        <Button
                                            type="submit"
                                            title="Submit"
                                            className={
                                                classes.CustomPrimaryButtonStyle
                                            }
                                            disabled={loading}
                                        >
                                            Update
                                        </Button>
                                        {loading && (
                                            <CircularProgress
                                                size={24}
                                                className={
                                                    classes.buttonProgress
                                                }
                                            />
                                        )}
                                    </div>

                                    <Button
                                        type="reset"
                                        title="Reset"
                                        className={
                                            classes.CustomDangerButtonStyle
                                        }
                                    >
                                        Reset
                                    </Button>
                                </Box>
                            </Grid>
                        </Form>
                    );
                }}
            />
        </>
    );
};
