import React, { useEffect, useState } from 'react';
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    Legend,
    CartesianGrid,
    Tooltip
} from 'recharts';
import withWidth from '@material-ui/core/withWidth';
import { LOGOUT, PER_DAY_SALE } from 'ConstComponents';

const AreaGraphChart = props => {
    const [perDaySaleModeList, setperDaySaleModeList] = useState([]);
    const [width, setWidth] = useState(1000);

    const selectPerDaySale = async () => {
        var request = JSON.stringify('');

        const response = await PER_DAY_SALE(request);

        if (!response.isAuthorized) LOGOUT();

        if (!response.status) {
            return;
        }
        var data = JSON.parse(response.data);

        setperDaySaleModeList(data);
    };

    useEffect(() => {
        selectPerDaySale();

        if (props.width === 'lg') {
            setWidth(1100);
        }
        if (props.width === 'md') {
            setWidth(900);
        }
        if (props.width === 'sm') {
            setWidth(500);
        }
        if (props.width === 'xl') {
            setWidth(1800);
        }
    }, [perDaySaleModeList.length]);

    var data = perDaySaleModeList;

    return (
        <AreaChart
            width={width}
            height={400}
            data={data}
            margin={{
                top: 10,
                right: 0,
                left: 0,
                bottom: 0
            }}
        >
            <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="25%" stopColor="#ffc658" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#ffc658" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="15%" stopColor="#82ca9d" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                </linearGradient>
            </defs>

            <CartesianGrid strokeDasharray="3 3" />
            <XAxis height={60} dataKey="DOC" />
            <YAxis />
            <Tooltip />
            <Legend
                height={36}
                iconType="circle"
                align="right"
                verticalAlign="top"
            />
            <Area
                type="monotone"
                dataKey="LastMonth"
                stroke="#ffc658"
                fillOpacity={1}
                fill="url(#colorUv)"
            />
            <Area
                type="monotone"
                dataKey="CurrentMonth"
                stroke="#82ca9d"
                fillOpacity={1}
                fill="url(#colorPv)"
            />
        </AreaChart>
    );
};

export default withWidth()(AreaGraphChart);
