import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Box from '@material-ui/core/Box';
import {
    CUSTOMER_SELECT_CUSTOMER,
    LOCATION_SELECT_ALL,
    PACKAGE_SELECT_ALL,
    CUSTOMER_UPDATE,
    LOGOUT
} from 'ConstComponents';

import { MaterialTextField } from 'Components/Inputes/FormikMaterialInput';
import { MaterialSelect } from 'Components/Inputes/FormikMaterialSelect';
import { MaterialNumberField } from 'Components/Inputes/FormikMaterialNumberInput';
import { CustomeAlert } from 'Components/Alert/Alert';
import { MenuItem, Grid, Paper, Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from 'Components/Loader/Loader';
import { useHistory } from 'react-router';
import { commonStyle } from 'Components/CustomMaterialStyleSheets/CommonStyleSheet';
import {
    MESSAGE_CLOSE_AFTER,
    PLEASE_PROVIDE_VALID,
    PLEASE_SELECT_VALID
} from 'Components/Helper/Messages';

export const CustomerEdit = () => {
    const classes = commonStyle();
    const history = useHistory();
    const [locationList, setLocationList] = useState([]);
    const [packageList, setPackageList] = useState([]);
    const [responseMessage, setResponseMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [loadingOpen, setLoadingOpen] = useState(false);

    const [CustomerObj, setCustomerObj] = useState({
        CustomerID: 0,
        PackageID: '',
        LocationID: '',
        CustomerName: '',
        CustomerMobile: '',
        SetupBoxNumber: '',
        DateofRegistration: '',
        Address: ''
    });

    const selectLocation = async values => {
        var request = JSON.stringify(values);

        const response = await LOCATION_SELECT_ALL(request);

        if (!response.isAuthorized) LOGOUT();
        var data = JSON.parse(response.data);
        setLocationList(data);
    };

    const selectPackage = async values => {
        const response = await PACKAGE_SELECT_ALL();

        var request = JSON.stringify(values);

        const packageResponse = await PACKAGE_SELECT_ALL(request);

        if (!response.isAuthorized) LOGOUT();
        var data = JSON.parse(packageResponse.data);
        setPackageList(data);
    };

    const selectCustomer = async () => {
        let URL = window.location.pathname;
        var res = URL.split('/');

        let CustomerID = res[res.length - 1];

        let JSONObj = {
            CustomerID: CustomerID
        };

        var request = JSON.stringify(JSONObj);
        setIsError(false);
        setLoadingOpen(true);

        const response = await CUSTOMER_SELECT_CUSTOMER(request);

        setLoadingOpen(false);
        setSeverity('success');

        if (!response.isAuthorized) {
            LOGOUT();
        }
        var data = JSON.parse(response.data);

        if (!response.status) {
            setSeverity('error');
            return;
        }

        CustomerObj.CustomerName = data.CustomerName;
        setCustomerObj({
            CustomerID: data.CustomerID,
            PackageID: data.PackageID,
            LocationID: data.LocationID,
            CustomerName: data.CustomerName,
            CustomerMobile: data.CustomerMobile,
            SetupBoxNumber: data.SetupBoxNumber,
            DateofRegistration: data.DateofRegistration,
            Address: data.Address
        });
    };

    useEffect(() => {
        selectLocation();
        selectPackage();
        selectCustomer();
    }, []);

    const submithandle = async (values, { setSubmitting, resetForm }) => {
        if (loading) {
            setLoading(false);
            return;
        }
        var requestObj = JSON.stringify(values);
        setIsError(false);
        setLoading(true);

        var response = await CUSTOMER_UPDATE(requestObj);
        if (!response.isAuthorized) {
            LOGOUT();
        }

        setIsError(true);
        setSeverity('success');
        setResponseMessage(response.data);

        if (!response.status) {
            setLoading(false);
            setSeverity('error');
            return;
        }

        resetForm(CustomerObj);

        setTimeout(() => {
            history.push('/Customer/Index');
        }, MESSAGE_CLOSE_AFTER);
    };

    const RegistrationScheama = Yup.object({
        LocationID: Yup.string()
            .notOneOf(['0'], PLEASE_SELECT_VALID + 'Location')
            .required(PLEASE_SELECT_VALID + 'Location'),

        PackageID: Yup.string()
            .notOneOf(['0'], PLEASE_SELECT_VALID + 'package')
            .required(PLEASE_SELECT_VALID + 'package'),

        CustomerName: Yup.string().required(
            PLEASE_PROVIDE_VALID + 'customer name'
        ),

        CustomerMobile: Yup.string()
            .length(10, PLEASE_PROVIDE_VALID + 'mobile number')
            .required(PLEASE_PROVIDE_VALID + 'mobile number'),

        SetupBoxNumber: Yup.string()
            .max(15, PLEASE_PROVIDE_VALID + 'setup box number')
            .required(PLEASE_PROVIDE_VALID + 'setup box number'),

        Address: Yup.string().required(PLEASE_PROVIDE_VALID + 'address')
    });
    const resethandle = resetForm => {};
    return (
        <>
            {isError ? (
                <CustomeAlert
                    isOpen={isError}
                    message={responseMessage}
                    severity={severity}
                    onCloseCallback={alertState => {
                        setIsError(alertState);
                    }}
                />
            ) : null}
            <Backdrop className={classes.backdrop} open={loadingOpen}>
                <Loader />
            </Backdrop>
            <Formik
                enableReinitialize={true}
                initialValues={CustomerObj}
                validationSchema={RegistrationScheama}
                onSubmit={submithandle}
                onReset={resethandle}
                render={formProps => {
                    return (
                        <Form>
                            <h2 className={classes.PaperHead_h2}>
                                Personal Details
                            </h2>
                            <Grid item xs={12}>
                                <Paper className={classes.PaperStyle}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <MaterialTextField
                                                label="Customer Name"
                                                name="CustomerName"
                                                type="text"
                                                title="Customer Name"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <MaterialNumberField
                                                label="Mobile Number"
                                                name="CustomerMobile"
                                                type="text"
                                                length={10}
                                                title="Mobile Number"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <MaterialTextField
                                                label="STB Number"
                                                name="SetupBoxNumber"
                                                length={15}
                                                type="text"
                                                title="STB Number"
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>

                            <h2 className={classes.PaperHead_h2}> Address </h2>
                            <Grid item xs={12}>
                                <Paper className={classes.PaperStyle}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <MaterialSelect
                                                label="Location"
                                                name="LocationID"
                                                title="Location ID"
                                            >
                                                <MenuItem value="0">
                                                    Select Location
                                                </MenuItem>
                                                {locationList.map(item => (
                                                    <MenuItem
                                                        key={item.LocationID}
                                                        value={item.LocationID}
                                                    >
                                                        {item.Location}
                                                    </MenuItem>
                                                ))}
                                            </MaterialSelect>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <MaterialTextField
                                                label="Address"
                                                name="Address"
                                                type="TextArea"
                                                icon="shop"
                                                title="Address"
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>

                            <h2 className={classes.PaperHead_h2}>
                                Package Details{' '}
                            </h2>
                            <Grid item xs={12}>
                                <Paper className={classes.PaperStyle}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <MaterialSelect
                                                label="Package"
                                                name="PackageID"
                                                title="Package ID"
                                            >
                                                <MenuItem value="0">
                                                    Select Package
                                                </MenuItem>
                                                {packageList.map(item => (
                                                    <MenuItem
                                                        key={item.PackageID}
                                                        value={item.PackageID}
                                                    >
                                                        {item.PackageName} Rs.
                                                        {item.Price} /-
                                                    </MenuItem>
                                                ))}
                                            </MaterialSelect>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid className={classes.footerGrid}>
                                <Box className={classes.flexReverse}>
                                    <div className={classes.SubmitWrapper}>
                                        <Button
                                            type="submit"
                                            title="Submit"
                                            className={
                                                classes.CustomPrimaryButtonStyle
                                            }
                                            disabled={loading}
                                        >
                                            Update
                                        </Button>
                                        {loading && (
                                            <CircularProgress
                                                size={24}
                                                className={
                                                    classes.buttonProgress
                                                }
                                            />
                                        )}
                                    </div>

                                    <Button
                                        type="reset"
                                        title="Reset"
                                        className={
                                            classes.CustomDangerButtonStyle
                                        }
                                    >
                                        Reset
                                    </Button>
                                </Box>
                            </Grid>
                        </Form>
                    );
                }}
            />
        </>
    );
};
