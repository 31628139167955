import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Box from '@material-ui/core/Box';
import {
    CUSTOMER_ADD,
    LOCATION_SELECT_ALL,
    PACKAGE_SELECT_ALL,
    LOGOUT
} from 'ConstComponents';

import { MaterialTextField } from 'Components/Inputes/FormikMaterialInput';
import { MaterialSelect } from 'Components/Inputes/FormikMaterialSelect';
import { MaterialNumberField } from 'Components/Inputes/FormikMaterialNumberInput';
import { CustomeAlert } from 'Components/Alert/Alert';
import { useHistory } from 'react-router';
import { MenuItem, Grid, Paper, Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { commonStyle } from 'Components/CustomMaterialStyleSheets/CommonStyleSheet';
import { MESSAGE_CLOSE_AFTER } from 'Components/Helper/Messages';

export const CustomerAdd = () => {
    const classes = commonStyle();
    const history = useHistory();
    const [locationList, setLocationList] = useState([]);
    const [packageList, setPackageList] = useState([]);
    const [responseMessage, setResponseMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [loading, setLoading] = useState(false);
    const selectLocation = async values => {
        var request = JSON.stringify(values);

        const response = await LOCATION_SELECT_ALL(request);

        if (!response.isAuthorized) LOGOUT();
        var data = JSON.parse(response.data);
        setLocationList(data);
    };

    const selectPackage = async values => {
        var request = JSON.stringify(values);

        const packageResponse = await PACKAGE_SELECT_ALL(request);

        if (!packageResponse.isAuthorized) LOGOUT();
        var data = JSON.parse(packageResponse.data);
        setPackageList(data);
    };

    useEffect(() => {
        selectLocation();
        selectPackage();
    }, []);

    var ModelObj = {
        PackageID: '',
        LocationID: '',
        CustomerName: '',
        CustomerMobile: '',
        SetupBoxNumber: '',
        Address: ''
    };

    const submithandle = async (values, { setSubmitting, resetForm }) => {
        var requestObj = JSON.stringify(values);
        setIsError(false);
        setLoading(true);

        var response = await CUSTOMER_ADD(requestObj);

        if (!response.isAuthorized) LOGOUT();

        setSeverity('success');
        setIsError(true);
        setResponseMessage(response.data);
        if (!response.status) {
            setLoading(false);
            setSeverity('error');
            return;
        }

        resetForm(ModelObj);

        setTimeout(() => {
            history.push('/Customer/Index');
        }, MESSAGE_CLOSE_AFTER);
    };

    const RegistrationScheama = Yup.object({
        LocationID: Yup.string()
            .trim()
            .notOneOf(['0'], 'Please select valid Location')
            .required('Please select valid Location'),

        PackageID: Yup.string()
            .trim()
            .notOneOf(['0'], 'Please select valid package')
            .required('Please select valid package'),

        CustomerName: Yup.string()
            .trim()
            .required('Please enter valid customer name'),

        CustomerMobile: Yup.string()
            .trim()
            .length(10, 'Please enter valid mobile number')
            .required('Please enter valid mobile number'),

        SetupBoxNumber: Yup.string()
            .trim()
            .max(15, 'Please enter valid setup box number')
            .required('Please enter valid setup box number'),

        Address: Yup.string()
            .trim()
            .required('Please enter valid address')
    });

    return (
        <>
            {isError ? (
                <CustomeAlert
                    isOpen={isError}
                    message={responseMessage}
                    severity={severity}
                    onCloseCallback={alertState => {
                        setIsError(alertState);
                    }}
                />
            ) : null}

            <Formik
                enableReinitialize={true}
                initialValues={ModelObj}
                validationSchema={RegistrationScheama}
                onSubmit={submithandle}
                resetForm={ModelObj}
                render={formProps => {
                    return (
                        <Form>
                            <h2 className={classes.PaperHead_h2}>
                                Personal Details
                            </h2>
                            <Grid item xs={12}>
                                <Paper className={classes.PaperStyle}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <MaterialTextField
                                                label="Customer Name"
                                                name="CustomerName"
                                                type="text"
                                                title="Customer Name"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <MaterialNumberField
                                                label="Mobile Number"
                                                name="CustomerMobile"
                                                type="text"
                                                length={10}
                                                title="Mobile Number"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <MaterialTextField
                                                label="STB Number"
                                                name="SetupBoxNumber"
                                                type="text"
                                                length={15}
                                                title="STB Number"
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>

                            <h2 className={classes.PaperHead_h2}> Address </h2>
                            <Grid item xs={12}>
                                <Paper className={classes.PaperStyle}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <MaterialSelect
                                                label="Location"
                                                name="LocationID"
                                                title="Location ID"
                                            >
                                                <MenuItem value="0">
                                                    Select Location
                                                </MenuItem>
                                                {locationList.map(item => (
                                                    <MenuItem
                                                        key={item.LocationID}
                                                        value={item.LocationID}
                                                    >
                                                        {item.Location}
                                                    </MenuItem>
                                                ))}
                                            </MaterialSelect>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <MaterialTextField
                                                label="Address"
                                                name="Address"
                                                type="TextArea"
                                                icon="shop"
                                                title="Address"
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>

                            <h2 className={classes.PaperHead_h2}>
                                Package Details
                            </h2>
                            <Grid item xs={12}>
                                <Paper className={classes.PaperStyle}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <MaterialSelect
                                                label="Package"
                                                name="PackageID"
                                                title="Package ID"
                                            >
                                                <MenuItem value="0">
                                                    Select Package
                                                </MenuItem>
                                                {packageList.map(item => (
                                                    <MenuItem
                                                        key={item.PackageID}
                                                        value={item.PackageID}
                                                    >
                                                        {item.PackageName} Rs.
                                                        {item.Price} /-
                                                    </MenuItem>
                                                ))}
                                            </MaterialSelect>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid className={classes.footerGrid}>
                                <Box className={classes.flexReverse}>
                                    <div className={classes.SubmitWrapper}>
                                        <Button
                                            type="submit"
                                            title="Register"
                                            className={
                                                classes.CustomPrimaryButtonStyle
                                            }
                                            disabled={loading}
                                        >
                                            Save
                                        </Button>
                                        {loading && (
                                            <CircularProgress
                                                size={24}
                                                className={
                                                    classes.buttonProgress
                                                }
                                            />
                                        )}
                                    </div>

                                    <Button
                                        type="reset"
                                        title="Reset"
                                        className={
                                            classes.CustomDangerButtonStyle
                                        }
                                    >
                                        Reset
                                    </Button>
                                </Box>
                            </Grid>
                        </Form>
                    );
                }}
            />
        </>
    );
};
