import { PostAPI } from 'Components/Helper/Helpers';

export const Add = async request => {
    return PostAPI(request, 'Customer/Insert');
};

export const SelectCustomerList = async request => {
    return PostAPI(request, 'Customer/Select');
};

export const SelectCustomerForDropDown = async request => {
    return PostAPI(request, 'Customer/SelectForDropDown');
};

export const Delete = async request => {
    return PostAPI(request, 'Customer/Delete');
};

export const SelectCustomer = async request => {
    return PostAPI(request, 'Customer/SelectMemberByID');
};
export const Update = async request => {
    return PostAPI(request, 'Customer/Update');
};
export const ForgotPasswordAPI = async request => {
    return PostAPI(request, 'Users/ForgotPassword');
};

export const Activate = async request => {
    return PostAPI(request, 'Customer/Activate');
};
