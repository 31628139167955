import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { useField } from 'formik';

export const AutoCompleteTextView = ({
    FieldName,
    textFieldProps,
    ...props
}) => {
    const {
        form: { setTouched, setFieldValue }
    } = props;

    const [field, meta] = useField(props);
    const error = meta.error[FieldName];
    const touched = meta.touched;

    return (
        <>
            <Autocomplete
                {...props}
                onChange={(_, value) => setFieldValue(FieldName, value)}
                onBlur={() => setTouched({ [FieldName]: true })}
                renderInput={params => (
                    <TextField
                        {...textFieldProps}
                        {...params}
                        helperText={touched && error ? error : null}
                        error={touched && error ? true : false}
                    />
                )}
            />
        </>
    );
};
