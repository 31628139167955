export const LOGOUT = require('Components/Helper/Helpers').logout;

export const LOGO = require('Assets/Images/avatar-sign.png');
export const COPYRIGHT = require('Components/Copyright/CopyRight').CopyRight;

export const GREENCHECKBOX = require('Components/Inputes/GreenCheckbox');

// -----****----Location API------ ****---//
export const LOCATION_SELECT_ALL = require('Components/Api/LocationApi').Select;
export const LOCATION_ADD = require('Components/Api/LocationApi').Add;
export const LOCATION_DELETE = require('Components/Api/LocationApi').Delete;
export const LOCATION_SELECT_LOCATION = require('Components/Api/LocationApi')
    .SelectLocation;
export const LOCATION_UPDATE = require('Components/Api/LocationApi').Update;

// -----****----Package API------ ****---//
export const PACKAGE_SELECT_ALL = require('Components/Api/PackageApi').Select;
export const PACKAGE_ADD = require('Components/Api/PackageApi').Add;
export const PACKAGE_DELETE = require('Components/Api/PackageApi').Delete;
export const PACKAGE_SELECT_PACKAGE = require('Components/Api/PackageApi')
    .SelectPackage;
export const PACKAGE_ACTIVATE = require('Components/Api/PackageApi').Activate;
export const PACKAGE_UPDATE = require('Components/Api/PackageApi').Update;

// -----****----Payment API------ ****---//
export const PAYMENTMODE_SELECT_ALL = require('Components/Api/PaymentModeApi')
    .Select;
export const PAYMENTMODE_ADD = require('Components/Api/PaymentModeApi').Add;
export const PAYMENTMODE_DELETE = require('Components/Api/PaymentModeApi')
    .Delete;
export const PAYMENTMODE_SELECT_PAYMENTMODE = require('Components/Api/PaymentModeApi')
    .SelectPaymentMode;
export const PAYMENTMODE_ACTIVATE = require('Components/Api/PaymentModeApi')
    .Activate;
export const PAYMENTMODE_UPDATE = require('Components/Api/PaymentModeApi')
    .Update;
//-----****-----Customer API----- ****--//

export const CUSTOMER_ADD = require('Components/Api/CustomerApi').Add;
export const CUSTOMER_SELECT_LIST = require('Components/Api/CustomerApi')
    .SelectCustomerList;
export const CUSTOMER_SELECT_FOR_DROPDOWN = require('Components/Api/CustomerApi')
    .SelectCustomerForDropDown;
export const CUSTOMER_DELETE = require('Components/Api/CustomerApi').Delete;
export const CUSTOMER_ACTIVATE = require('Components/Api/CustomerApi').Activate;
export const CUSTOMER_SELECT_CUSTOMER = require('Components/Api/CustomerApi')
    .SelectCustomer;
export const CUSTOMER_UPDATE = require('Components/Api/CustomerApi').Update;

//-----**** Agent API -----****---//

export const AGENT_ADD = require('Components/Api/AgentApi').Add;
export const AGENT_SELECT_LIST = require('Components/Api/AgentApi')
    .SelectAgentList;
export const AGENT_DELETE = require('Components/Api/AgentApi').Delete;
export const AGENT_ACTIVATE = require('Components/Api/AgentApi').Activate;
export const AGENT_SETASUSER = require('Components/Api/AgentApi').SetAsUser;
export const AGENT_SELECT_AGENT = require('Components/Api/AgentApi')
    .SelectAgentByID;
export const AGENT_UPDATE = require('Components/Api/AgentApi').Update;

// -----****---- Users API------ ****---//
export const USER_LOGIN = require('Components/Api/UsersApi').LoginAPI;
export const USER_ADD = require('Components/Api/UsersApi').Add;
export const USER_DELETE = require('Components/Api/UsersApi').Delete;
export const USER_ACTIVATE = require('Components/Api/UsersApi').Activate;
export const USER_SELECT_LIST = require('Components/Api/UsersApi')
    .SelectUserList;

export const USER_SELECT_FOR_DROPDOWN = require('Components/Api/UsersApi')
    .SelectForDropDown;

export const USER_SELECT_USER = require('Components/Api/UsersApi').SelectUser;
export const USER_FORGOT_PASSWORD = require('Components/Api/UsersApi')
    .ForgotPasswordAPI;

// -----****---- User Type API------ ****---//
export const USERTYPE_SELECT = require('Components/Api/UserTypeApi').Select;

// -----****---- Transaction API------ ****---//

export const TRANSACTION_INSERT = require('Components/Api/TransactionAPI')
    .Insert;
export const LAST_TEN_TRANSACTION = require('Components/Api/TransactionAPI')
    .SelectLastTenTx;
export const TODATE_FROMDATE_TRANSACTION = require('Components/Api/TransactionAPI')
    .SelectForFilter;
export const TRANSACTION_SELECT_TRANSACTION = require('Components/Api/TransactionAPI')
    .SelectTransaction;
export const TODATE_FROMDATE_SUMMARY = require('Components/Api/TransactionAPI')
    .SelectSummaryForFilter;

export const PER_DAY_SALE = require('Components/Api/TransactionAPI').PerDaySale;
// -----****---- SMS API------ ****---//

export const SMS_ADD = require('Components/Api/SMSApi').Add;
export const SMS_SELECT_LIST = require('Components/Api/SMSApi').SelectSmsList;

export const SMS_DELETE = require('Components/Api/SMSApi').Delete;

export const SMS_ACTIVATE = require('Components/Api/SMSApi').Activate;
export const SMS_SELECT_SMS = require('Components/Api/SMSApi').SelectSms;
export const SMS_UPDATE = require('Components/Api/SMSApi').Update;

// -----****---- Dasboard API------ ****---//
export const SELECT_DASHBOARD_DETAILS = require('Components/Api/DashBoardApi')
    .selectDashboardDetails;

// -----****---- Reconcilation API------ ****---//
export const RECONCILATION_SETTING_SELECT = require('Components/Api/ReconciliationAPI')
    .Select;
export const RECONCILATION_SETTING_ADD = require('Components/Api/ReconciliationAPI')
    .Insert;
export const RECONCILATION_UPLOAD = require('Components/Api/ReconciliationAPI')
    .Upload;
export const RECONCILATION_SELECT = require('Components/Api/ReconciliationAPI')
    .SelectReconciliation;
