import React from 'react';
import { useField } from 'formik';
import { TextField, FormControl } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    MaterialTextFieldStyle: {
        marginTop: '0px',
        height: '40px'
    }
}));

export const MaterialTextField = ({ label, ...props }) => {
    const classes = useStyles();
    const [field, meta] = useField(props);

    const maxLength = props.length ? props.length : null;

    return (
        <FormControl fullWidth>
            <TextField
                className={classes.MaterialTextFieldStyle}
                label={label}
                type={props.type}
                error={meta.touched && meta.error ? true : false}
                helperText={meta.touched && meta.error ? meta.error : null}
                inputProps={{ maxLength }}
                {...field}
                {...props}
            />
        </FormControl>
    );
};
