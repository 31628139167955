import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { SMS_UPDATE, SMS_SELECT_SMS, LOGOUT } from 'ConstComponents';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MaterialTextField } from 'Components/Inputes/FormikMaterialInput';
import { CustomeAlert } from 'Components/Alert/Alert';
import { Grid, Paper, Button } from '@material-ui/core';
import { useHistory } from 'react-router';
import { commonStyle } from 'Components/CustomMaterialStyleSheets/CommonStyleSheet';
import { MESSAGE_CLOSE_AFTER } from 'Components/Helper/Messages';
import Box from '@material-ui/core/Box';
export const SmsEdit = () => {
    const classes = commonStyle();
    const history = useHistory();
    const [responseMessage, setResponseMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [loading, setLoading] = useState(false);

    const [SmsObj, setSmsObj] = useState({
        SmsID: 0,
        SmsString: '',
        BalanceCheckUrl: ''
    });

    const selectSms = async () => {
        let URL = window.location.pathname;
        var res = URL.split('/');

        let SmsID = res[res.length - 1];

        let JSONObj = {
            SmsID: SmsID
        };

        var request = JSON.stringify(JSONObj);

        const response = await SMS_SELECT_SMS(request);

        if (!response.isAuthorized) {
            LOGOUT();
        }
        var data = JSON.parse(response.data);

        SmsObj.SmsString = data.SmsString;
        setSmsObj({
            SmsID: data.SmsID,
            SmsString: data.SmsString,
            BalanceCheckUrl: data.BalanceCheckUrl
        });
    };

    useEffect(() => {
        selectSms();
    }, []);

    const submithandle = async (values, { setSubmitting, resetForm }) => {
        var requestObj = JSON.stringify(values);
        setIsError(false);
        setLoading(true);
        var response = await SMS_UPDATE(requestObj);

        if (!response.isAuthorized) LOGOUT();

        setSeverity('success');
        setIsError(true);
        setResponseMessage(response.data);
        if (!response.status) {
            setSeverity('error');
            setLoading(false);
            return;
        }

        resetForm(SmsObj);

        setTimeout(() => {
            history.push('/SMS/Index');
        }, MESSAGE_CLOSE_AFTER);
    };

    const SmsScheama = Yup.object({
        SmsString: Yup.string()
            .trim()
            .required('Please enter valid sms string.'),

        BalanceCheckUrl: Yup.string()
            .trim()
            .required('Please enter valid balance url.')
    });
    const resethandle = resetForm => {};
    return (
        <>
            {isError ? (
                <CustomeAlert
                    isOpen={isError}
                    message={responseMessage}
                    severity={severity}
                    onCloseCallback={alertState => {
                        setIsError(alertState);
                    }}
                />
            ) : null}
            <Formik
                enableReinitialize={true}
                initialValues={SmsObj}
                validationSchema={SmsScheama}
                onSubmit={submithandle}
                resetForm={SmsObj}
                render={formProps => {
                    return (
                        <Form>
                            <h2 className={classes.PaperHead_h2}>
                                SMS Details{' '}
                            </h2>
                            <Grid item xs={12}>
                                <Paper className={classes.PaperStyle}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <MaterialTextField
                                                label="SmsString"
                                                name="SmsString"
                                                type="text"
                                                title="SMS URL"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <MaterialTextField
                                                label="BalanceCheckUrl"
                                                name="BalanceCheckUrl"
                                                type="text"
                                                title="Balance Check URL"
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>

                            <Grid className={classes.footerGrid}>
                                <Box className={classes.flexReverse}>
                                    <div className={classes.SubmitWrapper}>
                                        <Button
                                            type="submit"
                                            title="Submit"
                                            className={
                                                classes.CustomPrimaryButtonStyle
                                            }
                                            disabled={loading}
                                        >
                                            Update
                                        </Button>
                                        {loading && (
                                            <CircularProgress
                                                size={24}
                                                className={
                                                    classes.buttonProgress
                                                }
                                            />
                                        )}
                                    </div>

                                    <Button
                                        type="reset"
                                        title="Reset"
                                        className={
                                            classes.CustomDangerButtonStyle
                                        }
                                    >
                                        Reset
                                    </Button>
                                </Box>
                            </Grid>
                        </Form>
                    );
                }}
            />
        </>
    );
};
