import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Box from '@material-ui/core/Box';
import { MaterialDatePickerField } from 'Components/Inputes/MaterialDatePickerField';
import PrintIcon from '@material-ui/icons/Print';
import { MaterialSelect } from 'Components/Inputes/FormikMaterialSelect';

import { Container, Grid, Button, MenuItem, Paper } from '@material-ui/core';

import { CustomeAlert } from 'Components/Alert/Alert';
import {
    LOGOUT,
    TODATE_FROMDATE_TRANSACTION,
    PAYMENTMODE_SELECT_ALL,
    USER_SELECT_FOR_DROPDOWN,
    CUSTOMER_SELECT_FOR_DROPDOWN
} from 'ConstComponents';

import Backdrop from '@material-ui/core/Backdrop';
import ReactTable from 'Components/ReactTable/ReactTable';
import Loader from 'Components/Loader/Loader';
import { useHistory } from 'react-router';
import CircularProgress from '@material-ui/core/CircularProgress';
import { commonStyle } from 'Components/CustomMaterialStyleSheets/CommonStyleSheet';

import { AutoCompleteTextView } from 'Components/Inputes/AutoCompleteTextView';
import {
    PLEASE_SELECT_VALID,
    EMPLOYEE_ROLE_ID
} from 'Components/Helper/Messages';

export const Payment = () => {
    const classes = commonStyle();
    const history = useHistory();
    const [transactions, setTransactions] = useState([]);
    const [mainColumns, setMainColumns] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [paymentModeList, setpaymentModeList] = useState([]);
    const [loadingOpen, setLoadingOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [severity, setSeverity] = useState('success');

    const customerDefault = {
        CustomerID: 0,
        Name: 'Select All',
        Mobile: '',
        STB: ''
    };

    const userDefault = {
        UserID: 0,
        UserName: 'Select',
        MobileNo: 'All'
    };

    const [ModelObj] = useState({
        ToDate: new Date(),
        FromDate: new Date(),
        PaymentModeID: 0,
        User: {
            UserID: 0,
            UserName: 'Select',
            MobileNo: 'All'
        },
        Customer: {
            CustomerID: 0,
            CustomerMobile: 'All',
            SetupBoxNumber: 'All',
            CustomerName: 'Select'
        }
    });

    const submithandle = async (values, { setSubmitting, resetForm }) => {
        var inputObj = {
            FromDate: values.FromDate,
            ToDate: values.ToDate,
            PaymentModeID: values.PaymentModeID,
            UserID: values.User.UserID,
            CustomerID: values.Customer.CustomerID
        };

        setTransactions([]);

        var requestObj = JSON.stringify(inputObj);

        setIsError(false);
        setLoading(true);
        setLoadingOpen(true);
        var response = await TODATE_FROMDATE_TRANSACTION(requestObj);
        setLoading(false);
        setLoadingOpen(false);
        if (!response.isAuthorized) LOGOUT();

        setSeverity('success');

        if (!response.status) {
            setIsError(true);
            setResponseMessage(response.data);
            setSeverity('error');
            return;
        }

        setSubmitting(false);
        var data = JSON.parse(response.data);
        setTransactions(data);
    };

    const validationSchema = Yup.object({
        Customer: Yup.object()
            .nullable(PLEASE_SELECT_VALID + 'customer')
            .required(PLEASE_SELECT_VALID + 'customer')
            .shape({
                CustomerID: Yup.number()
                    .nullable(PLEASE_SELECT_VALID + 'customer')
                    .min(0, PLEASE_SELECT_VALID + 'customer')
                    .required(PLEASE_SELECT_VALID + 'customer')
            }),

        User: Yup.object()
            .nullable(PLEASE_SELECT_VALID + 'User')
            .required(PLEASE_SELECT_VALID + 'User')
            .shape({
                UserID: Yup.number()
                    .nullable(PLEASE_SELECT_VALID + 'User')
                    .min(0, PLEASE_SELECT_VALID + 'User')
                    .required(PLEASE_SELECT_VALID + 'User')
            }),

        FromDate: Yup.date()
            .nullable(PLEASE_SELECT_VALID + 'from date')
            .required(PLEASE_SELECT_VALID + 'date')
            .max(new Date(), PLEASE_SELECT_VALID + 'from date')
            .typeError(PLEASE_SELECT_VALID + 'from date'),

        ToDate: Yup.date()
            .nullable(PLEASE_SELECT_VALID + 'date')
            .required(PLEASE_SELECT_VALID + 'date')
            .max(new Date(), PLEASE_SELECT_VALID + 'to date')
            .typeError(PLEASE_SELECT_VALID + 'to date'),

        PaymentModeID: Yup.number()
            .required(PLEASE_SELECT_VALID + 'payment mode')
            .min(0, PLEASE_SELECT_VALID + 'payment mode')
            .typeError(PLEASE_SELECT_VALID + 'payment mode')
    });

    const SelectCustomer = async values => {
        setLoadingOpen(true);
        var request = JSON.stringify(values);
        setLoadingOpen(false);
        const customerResponse = await CUSTOMER_SELECT_FOR_DROPDOWN(request);
        if (!customerResponse.isAuthorized) LOGOUT();

        if (!customerResponse.status) {
            setIsError(true);
            setSeverity('error');
            return;
        }

        var data = JSON.parse(customerResponse.data);

        let objWithSelectAll = [customerDefault, ...data];

        setCustomerList(objWithSelectAll);
    };

    const SelectUser = async values => {
        var currentUsertypeid = sessionStorage.getItem('UserTypeID');

        if (EMPLOYEE_ROLE_ID === parseInt(currentUsertypeid)) {
            let objWithSelectAll = [userDefault];
            setUserList(objWithSelectAll);
            return;
        }

        setLoadingOpen(true);
        var request = JSON.stringify(values);
        setLoadingOpen(false);
        const userResponse = await USER_SELECT_FOR_DROPDOWN(request);

        if (!userResponse.isAuthorized) LOGOUT();
        if (!userResponse.status) {
            setIsError(true);
            setSeverity('error');
            return;
        }
        var data = JSON.parse(userResponse.data);

        let objWithSelectAll = [userDefault, ...data];

        setUserList(objWithSelectAll);
    };

    const selectPaymentMode = async values => {
        setLoadingOpen(true);
        var request = JSON.stringify(values);
        setLoadingOpen(false);
        const response = await PAYMENTMODE_SELECT_ALL(request);

        if (!response.isAuthorized) LOGOUT();
        if (!response.status) {
            setIsError(true);
            setSeverity('error');
            return;
        }
        var data = JSON.parse(response.data);
        setpaymentModeList(data);
    };

    const PrintCustomerTx = async request => {
        history.push('/Payment/Print/' + request);
    };

    useEffect(() => {
        SelectCustomer();
        selectPaymentMode();

        SelectUser();
        createColumns();
    }, []);

    const createColumns = () => {
        var columns = [];

        columns.push({
            label: '#',
            name: 'TransactionID',
            srno: true
        });

        columns.push({
            label: 'Date',
            name: 'DOC'
        });
        columns.push({
            label: 'Name',
            name: 'CustomerName'
        });
        columns.push({
            label: 'STB',
            name: 'SetBoxNumber'
        });

        columns.push({
            label: 'Amount',
            name: 'Amount',
            numeric: true
        });
        columns.push({
            label: 'Mode',
            name: 'PaymentMode'
        });
        columns.push({
            label: 'Received',
            name: 'UserName'
        });
        columns.push({
            label: 'Package',
            name: 'PackageName'
        });
        columns.push({
            label: 'Remark',
            name: 'Remark',
            textwrapwidth: 100,
            textwrap: true
        });
        columns.push({
            label: 'Print',
            name: 'TransactionID',
            options: {
                filter: false,
                sort: false,
                download: false,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <PrintIcon
                            variant="contained"
                            color="primary"
                            onClick={event => {
                                PrintCustomerTx(value);
                            }}
                        />
                    );
                }
            }
        });

        setMainColumns(columns);
    };

    return (
        <>
            <Backdrop className={classes.backdrop} open={loadingOpen}>
                <Loader />
            </Backdrop>

            {isError ? (
                <CustomeAlert
                    isOpen={isError}
                    message={responseMessage}
                    severity={severity}
                    onCloseCallback={alertState => {
                        setIsError(alertState);
                    }}
                />
            ) : null}
            <Formik
                initialValues={ModelObj}
                validationSchema={validationSchema}
                onSubmit={submithandle}
                resetForm={ModelObj}
                render={formProps => {
                    return (
                        <Form>
                            <h2 className={classes.PaperHead_h2}>
                                Payment Report
                            </h2>
                            <Grid item xs={12}>
                                <Paper className={classes.PaperStyle}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={4} item>
                                            <MaterialDatePickerField
                                                name="FromDate"
                                                label="From Date"
                                                maxDate={new Date()}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={4} item>
                                            <MaterialDatePickerField
                                                name="ToDate"
                                                label="To Date"
                                                maxDate={new Date()}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={4} item>
                                            <MaterialSelect
                                                label="Payment Mode"
                                                name="PaymentModeID"
                                                title="Payment Mode"
                                            >
                                                <MenuItem key="0" value="0">
                                                    Select All
                                                </MenuItem>
                                                {paymentModeList.map(item => (
                                                    <MenuItem
                                                        key={item.PaymentModeID}
                                                        value={
                                                            item.PaymentModeID
                                                        }
                                                    >
                                                        {item.PaymentMode}
                                                    </MenuItem>
                                                ))}
                                            </MaterialSelect>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <h2 className={classes.PaperHead_Blank} />

                            <Grid item xs={12}>
                                <Paper className={classes.PaperStyle}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={6} item>
                                            <Field
                                                component={AutoCompleteTextView}
                                                FieldName="Customer"
                                                defaultValue={customerDefault}
                                                name="Customer"
                                                options={customerList}
                                                getOptionLabel={option =>
                                                    option.Name
                                                        ? option.Name ===
                                                          'Select All'
                                                            ? option.Name
                                                            : option.Name +
                                                              ' (' +
                                                              option.STB +
                                                              ') [' +
                                                              option.Mobile +
                                                              ']'
                                                        : null
                                                }
                                                textFieldProps={{
                                                    label: 'Select Customer',
                                                    margin: 'normal'
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} item>
                                            <Field
                                                component={AutoCompleteTextView}
                                                defaultValue={userDefault}
                                                FieldName="User"
                                                name="User"
                                                options={userList}
                                                getOptionLabel={option =>
                                                    option.UserName +
                                                    ' - ' +
                                                    option.MobileNo
                                                }
                                                textFieldProps={{
                                                    label: 'Select User',
                                                    margin: 'normal'
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>

                            <Grid className={classes.footerGrid}>
                                <Box className={classes.flexReverse}>
                                    <div className={classes.SubmitWrapper}>
                                        <Button
                                            type="submit"
                                            title="submit"
                                            className={
                                                classes.CustomPrimaryButtonStyle
                                            }
                                            disabled={loading}
                                        >
                                            Search
                                        </Button>
                                        {loading && (
                                            <CircularProgress
                                                size={24}
                                                className={
                                                    classes.buttonProgress
                                                }
                                            />
                                        )}
                                    </div>
                                </Box>
                            </Grid>
                        </Form>
                    );
                }}
            />

            <ReactTable List={transactions} columns={mainColumns} />
        </>
    );
};
