import React from 'react';

import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import ListItemText from '@material-ui/core/ListItemText';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import { makeStyles } from '@material-ui/core/styles';
import InboxIcon from '@material-ui/icons/Inbox';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { CustomIcon } from 'Components/CustomIcons';

import { SingleElement } from './SingleElement';

const useStyles = makeStyles(theme => ({
    nested: {
        paddingLeft: theme.spacing(5)
    }
}));

export const DropDownMenu = ({ name, children, icon }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <ListItem button onClick={() => setOpen(!open)}>
                <ListItemIcon>
                    {icon === null ? <InboxIcon /> : <CustomIcon icon={icon} />}
                </ListItemIcon>
                <ListItemText primary={name} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {children.map((subOption, index) => {
                    return (
                        <SingleElement
                            key={index}
                            name={subOption.name}
                            url={subOption.url}
                            classes={classes.nested}
                            icon={subOption.icon}
                        />
                    );
                })}
            </Collapse>
        </>
    );
};
