import React from 'react';
import { useField } from 'formik';
import { Select, FormControl, FormHelperText } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    MaterialTextFieldStyle: {
        marginTop: '8px',
        height: '40px'
    }
}));

export const MaterialSelect = ({ label, ...props }) => {
    const classes = useStyles();
    const [field, meta] = useField(props);
    return (
        <FormControl
            fullWidth
            error={meta.touched && meta.error ? true : false}
        >
            <InputLabel id="demo-simple-select-helper-label">
                {label}
            </InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className={classes.MaterialTextFieldStyle}
                {...field}
                {...props}
            />
            <FormHelperText>
                {meta.touched && meta.error ? meta.error : null}
            </FormHelperText>
        </FormControl>
    );
};
