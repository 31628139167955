import React from 'react';
import InboxIcon from '@material-ui/icons/Inbox';
import DashboardIcon from '@material-ui/icons/Dashboard';
import MoneyIcon from '@material-ui/icons/Money';
import ReportIcon from '@material-ui/icons/Report';
import PeopleIcon from '@material-ui/icons/People';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import PinDropIcon from '@material-ui/icons/PinDrop';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import AddToQueueIcon from '@material-ui/icons/AddToQueue';
import MobileFriendlyIcon from '@material-ui/icons/MobileFriendly';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import TvIcon from '@material-ui/icons/Tv';
import PersonIcon from '@material-ui/icons/Person';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PaymentIcon from '@material-ui/icons/Payment';
import ChatIcon from '@material-ui/icons/Chat';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import FaceIcon from '@material-ui/icons/Face';
import SettingsIcon from '@material-ui/icons/Settings';
import PublishIcon from '@material-ui/icons/Publish';
import CompareIcon from '@material-ui/icons/Compare';
export const CustomIcon = props => {
    const getIcon = param => {
        switch (param) {
            case 'DashboardIcon':
                return <DashboardIcon style={{ color: '#47BEA0' }} />;

            case 'MoneyIcon':
                return <MoneyIcon style={{ fill: 'blue' }} />;

            case 'ReportIcon':
                return <ReportIcon />;

            case 'PeopleIcon':
                return <PeopleIcon style={{ fill: 'black' }} />;

            case 'PeopleAltIcon':
                return <PeopleAltIcon />;
            case 'PaymentIcon':
                return <PaymentIcon />;
            case 'PersonAddIcon':
                return <PersonAddIcon />;
            case 'FaceIcon':
                return <FaceIcon style={{ fill: 'green' }} />;
            case 'AssignmentIndIcon':
                return <AssignmentIndIcon />;
            case 'PersonIcon':
                return <PersonIcon style={{ color: '#319D8E' }} />;
            case 'ListAltIcon':
                return <ListAltIcon />;

            case 'SettingsIcon':
                return <SettingsIcon style={{ fill: 'red' }} />;

            case 'PublishIcon':
                return <PublishIcon style={{ color: '#319D8E' }} />;

            case 'ChatIcon':
                return <ChatIcon style={{ color: '#F6B501' }} />;
            case 'ExitToAppIcon':
                return <ExitToAppIcon style={{ fill: 'red' }} />;
            case 'LocationOnIcon':
                return <LocationOnIcon />;
            case 'PinDropIcon':
                return <PinDropIcon style={{ color: '#FF7769' }} />;
            case 'AddLocationIcon':
                return <AddLocationIcon />;
            case 'LiveTvIcon':
                return <LiveTvIcon style={{ fill: 'red' }} />;
            case 'AddToQueueIcon':
                return <AddToQueueIcon />;
            case 'TvIcon':
                return <TvIcon />;
            case 'CompareIcon':
                return <CompareIcon style={{ color: '#319D8E' }} />;
            case 'ChatBubbleOutlineIcon':
                return <ChatBubbleOutlineIcon />;

            case 'MobileFriendlyIcon':
                return <MobileFriendlyIcon />;

            default:
                return <InboxIcon />;
        }
    };

    return <>{getIcon(props.icon)}</>;
};
