import { PostAPI } from 'Components/Helper/Helpers';

export const SelectSmsList = async request => {
    return PostAPI(request, 'SMS/Select');
};
export const Add = async request => {
    return PostAPI(request, 'SMS/Insert');
};

export const Activate = async request => {
    return PostAPI(request, 'SMS/Activate');
};

export const SelectSms = async request => {
    return PostAPI(request, 'SMS/SelectSmsByID');
};
export const Update = async request => {
    return PostAPI(request, 'SMS/Update');
};
export const Delete = async request => {
    return PostAPI(request, 'SMS/Delete');
};
