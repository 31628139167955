import { SecurityServices } from '../EncryptDecrypt/Crypto';
import { SERVER_DOWN_ERROR } from './Messages';
import { IsValidString, IsValidStatusCode } from './Validation';
import axios from 'axios';

import { API_URL } from 'Config';

export var KEY_DATA = '';

const getBaseUrl = async () => {
    const BASE_URL = await API_URL();
    return BASE_URL;
};

export const axiosInstance = axios.create({
    timeout: 5 * 60 * 1000
});

axiosInstance.interceptors.request.use(
    async config => {
        config.baseURL = await getBaseUrl();
        return config;
    },
    error => Promise.reject(error)
);

export const getKeyData = () => {
    let UserID = sessionStorage.getItem('UserID');
    let FirmID = sessionStorage.getItem('FirmID');

    let JsonKeyData = {
        UserID: UserID,
        FirmID: FirmID,
        IMEI: '00001111144444',
        Platform: 'WEB'
    };
    let KeyData = JSON.stringify(JsonKeyData);
    KeyData = SecurityServices.encryptFun(KeyData);
    return KeyData;
};

export const GetDefaultResponse = {
    status: false,
    data: ''
};

export const GetFormData = Request => {
    var form = new FormData();
    form.append('Request', SecurityServices.encryptFun(Request));
    form.append('KeyData', getKeyData());
    return form;
};

export const getAuthorizationHeader = () => {
    return sessionStorage.getItem('SecureToken');
};

export const PostAPI = async (request, url) => {
    var response = GetResponseClass();
    var form = GetFormData(request);
    try {
        const { status, data } = await axiosInstance.post(url, form, {
            headers: {
                Authorization: 'Bearer ' + getAuthorizationHeader(),
                'Content-Type': 'application/json'
            }
        });
        return ParseResponse(status, data, response);
    } catch (error) {
        return ParseErrorResponse(error, response);
    }
};

export const logout = () => {
    sessionStorage.setItem('UserTypeID', 0);
    sessionStorage.setItem('UserID', 0);
    sessionStorage.setItem('Token', '');
    sessionStorage.setItem('Authorize', false);
    sessionStorage.clear();
    window.location.href = '/Login';
};

export const GetResponseClass = () => {
    return {
        isAuthorized: false,
        status: false,
        data: 'Unable to process request. Please try after some time!'
    };
};

export const ParseResponse = (status, data, response) => {
    if (!IsValidStatusCode(status)) {
        response.data =
            'Unable to process request. Please try after some time!';
        return response;
    }

    if (status !== 200) {
        response.data =
            'Unable to process request. Please try after some time! : ' +
            status;
        return response;
    }
    if (status === 401) {
        response.data =
            'Unable to process request. Please try after some time! : ' +
            status;
        response.isAuthorized = false;
        return response;
    }

    if (!IsValidString(data.body)) {
        response.data =
            'Unable to process request. Please try after some time!';
        return response;
    }

    let decryptedBody = SecurityServices.decryptFun(data.body);

    if (!IsValidString(decryptedBody)) {
        response.data =
            'Unable to process request. Please try after some time!';
        return response;
    }
    response.isAuthorized = true;
    response.status = data.status;
    response.data = decryptedBody;

    return response;
};

export const ParseErrorResponse = (error, returnResponse) => {
    if (error.message === 'Network Error') {
        returnResponse.data = SERVER_DOWN_ERROR;
        return returnResponse;
    }
    returnResponse.isAuthorized = false;
    returnResponse.data = 'Request failed with : ' + error.response.status;
    return returnResponse;
};

export const textToSpeech = text => {
    let speaker = new SpeechSynthesisUtterance();
    speaker.lang = 'en-IN';
    speaker.text = text;
    speechSynthesis.speak(speaker);
};
