import React, { Component, lazy } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Redirect,
    Switch
} from 'react-router-dom';

import './Assets/CSS/index.scss';
import CustomSuspense from 'Components/CustomSuspense';

/*********** layouts *************/
import OuterLayoutRoute from './Pages/Layouts/OuterLayout';
import InnerLayoutRoute from './Pages/Layouts/InnerLayout';

/**************** outer pages **************************/
import { Login } from './Pages/OuterPages/Login';
import { Home } from './Pages/OuterPages/Home';
import { AboutUs } from './Pages/OuterPages/AboutUs';

/**************** inner pages **************************/
import { Customer } from './Pages/InnerPages/Customer/Index';
import { CustomerAdd } from './Pages/InnerPages/Customer/CustomerAdd';
import { CustomerEdit } from './Pages/InnerPages/Customer/CustomerEdit';

import { Agent } from './Pages/InnerPages/Agent/Index';
import { AgentAdd } from './Pages/InnerPages/Agent/AgentAdd';
import { AgentEdit } from './Pages/InnerPages/Agent/AgentEdit';

import { User } from './Pages/InnerPages/User/Index';

import { FirmOwnerDashboard } from './Pages/InnerPages/FirmOwnerDashboard';

import { Payment } from './Pages/InnerPages/Payment/Index';
import { PaymentAdd } from './Pages/InnerPages/Payment/PaymentAdd';

import { Summary } from './Pages/InnerPages/Summary/Index';

import { Locations } from './Pages/InnerPages/Location/Index';
import { LocationAdd } from './Pages/InnerPages/Location/LocationAdd';
import { LocationEdit } from './Pages/InnerPages/Location/LocationEdit';

import { Packages } from './Pages/InnerPages/Package/Index';
import { PackageAdd } from './Pages/InnerPages/Package/PackageAdd';
import { PackageEdit } from './Pages/InnerPages/Package/PackageEdit';

import { PaymentMode } from './Pages/InnerPages/PaymentMode/Index';
import { PaymentModeAdd } from './Pages/InnerPages/PaymentMode/PaymentModeAdd';
import { PaymentModeEdit } from './Pages/InnerPages/PaymentMode/PaymentModeEdit';

import { SmsAdd } from './Pages/InnerPages/SMS/SmsAdd';
import { Sms } from './Pages/InnerPages/SMS/Index';
import { SmsEdit } from './Pages/InnerPages/SMS/SmsEdit';

import { ReconciliationUpload } from './Pages/InnerPages/Reconciliation/ReconcilationUpload';
import { ReconciliationSetting } from './Pages/InnerPages/Reconciliation/ReconciliationSetting';
import { ReconcilationReport } from './Pages/InnerPages/Reconciliation/ReconcilationReport';

const PaymentPrint = lazy(() =>
    import('./Pages/InnerPages/Payment/PaymentPrint')
);

export default class App extends Component {
    static displayName = App.name;
    render() {
        return (
            <Router>
                <Switch>
                    <CustomSuspense>
                        <Route exact path="/">
                            <Redirect to="/Login" />
                        </Route>
                        <InnerLayoutRoute
                            path="/Dashboard"
                            component={FirmOwnerDashboard}
                        />
                        <OuterLayoutRoute path="/Home" component={Home} />
                        <OuterLayoutRoute path="/Login" component={Login} />
                        <OuterLayoutRoute path="/AboutUs" component={AboutUs} />
                        <InnerLayoutRoute
                            path="/FirmOwnerDashboard"
                            component={FirmOwnerDashboard}
                        />
                        <InnerLayoutRoute path="/User/Index" component={User} />
                        <InnerLayoutRoute
                            path="/Payment/Index"
                            component={Payment}
                        />
                        <InnerLayoutRoute
                            path="/Summary/Index"
                            component={Summary}
                        />
                        <InnerLayoutRoute
                            path="/Payment/Print"
                            component={PaymentPrint}
                        />
                        <InnerLayoutRoute
                            path="/Payment/Add"
                            component={PaymentAdd}
                        />
                        <InnerLayoutRoute
                            path="/Customer/Add"
                            component={CustomerAdd}
                        />
                        <InnerLayoutRoute
                            path="/Customer/Edit"
                            component={CustomerEdit}
                        />
                        <InnerLayoutRoute
                            path="/Customer/Index"
                            component={Customer}
                        />
                        <InnerLayoutRoute
                            path="/Agent/Add"
                            component={AgentAdd}
                        />
                        <InnerLayoutRoute
                            path="/Agent/Index"
                            component={Agent}
                        />
                        <InnerLayoutRoute
                            path="/Agent/Edit"
                            component={AgentEdit}
                        />
                        <InnerLayoutRoute
                            path="/Location/Add"
                            component={LocationAdd}
                        />
                        <InnerLayoutRoute
                            path="/Location/Edit"
                            component={LocationEdit}
                        />
                        <InnerLayoutRoute
                            path="/Package/Edit"
                            component={PackageEdit}
                        />
                        <InnerLayoutRoute
                            path="/Location/Index"
                            component={Locations}
                        />
                        <InnerLayoutRoute
                            path="/Package/Index"
                            component={Packages}
                        />
                        <InnerLayoutRoute
                            path="/Package/Add"
                            component={PackageAdd}
                        />
                        <InnerLayoutRoute
                            path="/PaymentMode/Add"
                            component={PaymentModeAdd}
                        />
                        <InnerLayoutRoute
                            path="/PaymentMode/Index"
                            component={PaymentMode}
                        />
                        <InnerLayoutRoute
                            path="/PaymentMode/Edit"
                            component={PaymentModeEdit}
                        />{' '}
                        <InnerLayoutRoute path="/SMS/Add" component={SmsAdd} />
                        <InnerLayoutRoute path="/SMS/Index" component={Sms} />
                        <InnerLayoutRoute
                            path="/SMS/Edit"
                            component={SmsEdit}
                        />
                        <InnerLayoutRoute
                            path="/Reconciliation/ReconciliationUpload"
                            component={ReconciliationUpload}
                        />
                        <InnerLayoutRoute
                            path="/Reconciliation/ReconciliationSetting"
                            component={ReconciliationSetting}
                        />
                        <InnerLayoutRoute
                            path="/Reconciliation/ReconcilationReport"
                            component={ReconcilationReport}
                        />
                    </CustomSuspense>
                </Switch>
            </Router>
        );
    }
}
